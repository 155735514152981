<template>
  <h1 class="content-title"> {{ $t('venteAuDetailHome') }} </h1>
  <div class="mb-4 mt-4">
    <p>Devant les exigences croissantes des clients, les commerces de vente au détail doivent continuer d’innover et d’adopter de nouvelles technologies. Les habitudes des consommateurs évoluent de plus en plus vers le commerce en ligne. Que ce soit pour le marketing, le service à la clientèle, la facturation ou la gestion, l’IA pourrait devenir votre employé du mois.</p>
    <p>Vous êtes invité à utiliser le livre numérique pour noter vos idées ou vos réflexions, ou encore pour surligner un extrait de texte particulièrement pertinent à vos yeux.</p>
  </div>
</template>

<script>
export default {
  name: "VenteAuDetailHome"
}
</script>

<style scoped>

</style>