<template>
  <div>
    <h1 class="content-title">{{ $t("home") }}</h1>
    <div>
      <h2>L’intelligence artificielle : un monde de possibilités !</h2>
      <p>
        Les technologies de l’intelligence artificielle (IA), appelées à évoluer à une vitesse croissante, sont adoptées par de plus en plus d’entreprises et d’organisations qui veulent notamment accroître leur productivité, pallier un manque de main-d’œuvre ou répondre de manière plus adéquate aux besoins de leur clientèle. Découvrez les cas d’usage de l’IA et les témoignages des entrepreneurs et des conseillers experts qui ont plongé dans l’aventure. Quels sont les impacts des projets en IA sur un modèle d’affaires ? Quelles questions d’intérêt public soulèvent ces innovations ? EntrepreneurIA offre quelques pistes de réflexion sur ces sujets et suggère des voies à explorer pour financer les projets.
      </p>
      <h2>Mais qu’est-ce que l’intelligence artificielle ?</h2>
      <p>
        Définir ce qu’est l’IA n’est pas une mince affaire : même les plus grands spécialistes en la matière ne s’entendent pas sur une définition qui fait consensus !
      </p>
      <p><strong>Intelligence artificielle (IA)</strong>&nbsp;: «&nbsp;Domaine d’étude ayant pour objet la reproduction artificielle des facultés cognitives de l’intelligence humaine dans le but de créer des systèmes ou des machines capables d’exécuter des fonctions relevant normalement de celle-ci.&nbsp;»</p>
      <p>Essentiellement, cela veut dire que les technologies en IA permettent de classer des données, d’émettre des prédictions, d’automatiser des tâches et d’optimiser des opérations. Les quatre principales technologies en IA qui seront abordées sur EntrepreneurIA sont les suivantes&nbsp;:</p>
      <ul>
        <li><strong>L’apprentissage automatique</strong> Les modèles d’apprentissage automatique peuvent accomplir plusieurs tâches, comme classer, identifier, localiser, prédire et segmenter des données.</li>
        <li><strong>La reconnaissance de la parole</strong> Cette technologie permet à un système de reconnaître des mots et des phrases, de les classifier et de les traiter pour créer du sens.</li>
        <li><strong>La reconnaissance d’images</strong> Cette technologie permet d’analyser, de traiter, de détecter et de reconnaître des données visuelles comme des images et des vidéos.</li>
        <li><strong>La robotisation</strong> Cette technologie permet de traiter des données pour commander à un appareil robotisé d’effectuer des actions, d’apprendre et même de prendre des décisions.</li>
      </ul>
      <h2>Les secteurs d’activité traités sur EntrepreneurIA</h2>
      <p>Le site EntrepreneurIA décrit des cas d’usage de l’IA dans 5 secteurs d’activité : <router-link to="agricultureHome">agriculture</router-link>, <router-link to="financeEtAssuranceHome">finance et assurance</router-link>, <router-link to="industrieManufacturiereHome">industrie manufacturière</router-link>, <router-link to="santeHome">santé</router-link> et <router-link to="venteAuDetailHome">vente au détail</router-link>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped></style>
