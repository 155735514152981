<template>
  <h1 class="content-title">Les enjeux éthiques de l'intelligence artificielle en finance et en assurance</h1>
  <div class="mb-4 mt-4">
    <p>L’intégration de l’IA aux domaines de la finance et des assurances laisse entrevoir des possibilités d’applications prometteuses. Cependant, l’utilisation de l’IA soulève aussi des enjeux éthiques. Une compréhension de ces enjeux permettra de mieux planifier l’implantation de technologies basées sur l’IA. Voici les principaux enjeux éthiques posés par l’IA et quelques pistes à explorer pour tenter d’y répondre.</p>
    <h4>Limiter les pertes d’emplois et réorganiser le travail</h4>
    <p>Grâce à sa puissance, l’IA peut automatiser une certaine partie des opérations dans les domaines de la finance et des assurances. Mentionnons, notamment, des tâches comme&nbsp;:</p>
    <ul>
      <li>le traitement de documents (p. ex. des chèques)&nbsp;;</li>
      <li>l’analyse de réclamations&nbsp;;</li>
      <li>l’optimisation de placements et d’investissements&nbsp;;</li>
      <li>l’amélioration de l’expérience client&nbsp;;</li>
      <li>l’automatisation de certains processus&nbsp;;</li>
      <li>la prévention de la fraude.</li>
    </ul>
    <p>Cette optimisation du travail peut ainsi se traduire par une réduction du nombre de postes requis pour atteindre une productivité équivalente ou supérieure.</p>
    <p>L’utilisation de l’IA peut aussi favoriser une concentration des activités dans les sièges sociaux, ce qui peut entraîner la suppression d’emplois auxiliaires (p. ex. soutien administratif) dans certains centres.</p>
    <p>Cependant, la plupart des tâches qui peuvent être effectuées par l’IA nécessitent une supervision humaine. L’IA peut donc être vue comme un soutien et une valeur ajoutée au travail du personnel déjà présent.</p>
    <p>Il est ainsi possible d’implanter l’IA sans que l’adoption de cette technologie soit forcément synonyme d’une perte significative d’emplois. Une réorganisation du travail ainsi que des formations pour mettre les connaissances à niveau peuvent faciliter la transition.</p>
    <p>L’intégration de l’IA peut aussi mener à la création de nouveaux emplois dans des postes qui n’existaient pas auparavant. Par exemple, une équipe de formation pourrait être constituée pour expliquer les avantages que procure l’IA dans les services offerts. Ces formations pourraient être offertes tant à la clientèle qu’au personnel de l’entreprise.</p>
    <h4>Assurer la cybersécurité</h4>
    <p>Les données utilisées par l’IA dans les secteurs de la finance et des assurances sont souvent très personnelles, comme&nbsp;:</p>
    <ul>
      <li>la cote de crédit&nbsp;;</li>
      <li>le statut d’emploi et le revenu&nbsp;;</li>
      <li>l’état de santé.</li>
    </ul>
    <p>Les données peuvent aussi être de nature plus commerciale (p. ex. le rendement d’une entreprise). La grande valeur de ces informations attire la convoitise des pirates informatiques. Ces derniers emploient des techniques de plus en plus sophistiquées pour s’emparer de ces données ou pour en bloquer l’accès. Lorsqu’ils parviennent à leurs fins, les pirates réclament souvent le paiement d’une rançon en échange du contrôle des données.</p>
    <p>Ces cyberattaques présentent un risque pour la réputation des organisations. Une attaque réussie peut provoquer une baisse de confiance chez la clientèle, ce qui peut avoir des conséquences négatives sur la santé financière de l’entreprise.</p>
    <p>Ainsi, il est prudent d’analyser les risques associés à la sécurité et à la gouvernance des données utilisées ainsi que ceux liés à l’environnement informatique. Cette analyse permettra la mise en place de solutions pour pallier les failles identifiées. Les initiatives prises pour apporter des solutions nécessiteront des mises à jour fréquentes pour assurer une protection continue.</p>
    <p>Les méthodes d’identification méritent une attention particulière. Par exemple, certaines technologies ont été conçues pour imiter le timbre de la voix d’une personne en particulier. Ces fausses voix arrivent parfois à confondre les systèmes d’identification basés sur la reconnaissance vocale. Dans ce cas-ci, le recours à la double authentification est une mesure à envisager.</p>
    <h4>Préserver la transparence des données, la responsabilité et l’imputabilité humaine</h4>
    <p>Bien qu’elle soit très puissante, l’IA ne garantit pas une absence complète de fautes ou de biais. Le recours à ce genre de technologie demande donc une certaine vigilance humaine.</p>
    <p>Prenons le cas d’un système d’IA qui croit détecter, par erreur, des activités suspectes liées à un compte. Le système pourrait alors choisir de bloquer ce compte automatiquement. Pourtant, ces activités, bien qu’inhabituelles, pourraient tout à fait être légitimes. Il devient difficile de trouver les causes de cette erreur si personne n’a participé à la prise de décision. Il est également plus délicat d’identifier qui est imputable de cette décision.</p>
    <p>Ainsi, l’implication d’êtres humains dans le processus décisionnel garantit l’imputabilité des actions posées. La participation humaine permet aussi de préserver le principe de la responsabilité professionnelle. De plus, la clientèle doit pouvoir obtenir des explications si elle n’est pas satisfaite d’une décision rendue.</p>
    <p>Les personnes responsables ont donc le mandat d’évaluer les recommandations faites par l’IA. Elles pourront ensuite déterminer si ces suggestions sont appropriées dans le contexte donné. Il peut être utile de favoriser le recours à des données explicables et à des systèmes plus transparents qui précisent les motifs de leurs recommandations. Un plus grand niveau de détails simplifiera l’évaluation, la prise de décision, et donnera la possibilité de fournir des explications à la clientèle.</p>
    <p>Pour faciliter l’évaluation d’une recommandation, il est également possible de définir&nbsp;:</p>
    <ul>
      <li>les conditions à observer pour valider une décision&nbsp;;</li>
      <li>les types d’erreurs les plus fréquentes commises selon leur origine (humaine ou informatique) afin de les corriger&nbsp;;</li>
      <li>les niveaux acceptables de tolérance de ces erreurs selon leur origine (humaine ou informatique).</li>
    </ul>
    <p>En déterminant ces critères au préalable, il deviendra plus facile de gérer les situations plus complexes.</p>
    <h4>Éviter la présence de biais et favoriser l’équité</h4>
    <p>Les technologies basées sur l’IA ont tendance à perpétuer les inexactitudes et les biais présents dans les données qui ont servi à leur entraînement. En effet, les données utilisées ont été générées et traitées par des humains qui ne sont, eux-mêmes, pas à l’abri de biais. Ainsi, lorsqu’ils sont entraînés avec des données biaisées, les systèmes qui recourent à l’IA apprennent à reproduire ces biais.</p>
    <p>Par exemple, historiquement, les femmes ont reçu moins de prêts que les hommes. Un système d’allocation de prêt basé sur l’IA risque donc d’être discriminatoire s’il est seulement entraîné avec des données historiques. Ce système serait automatiquement moins généreux avec les femmes simplement en raison de leur sexe. Pour atténuer l’effet de ce biais, il convient d’accorder un traitement spécial aux données originales.</p>
    <p>Comme l’IA est encore peu encadrée, il est préférable de porter une attention particulière à la présence possible de biais. Cependant, il n’est pas toujours facile de détecter la discrimination involontaire faite par une application basée sur l’IA. En effet, les raisons derrière les décisions prises grâce à l’IA ne sont pas systématiquement expliquées de manière transparente.</p>
    <p>L’implantation de l’IA doit donc se faire de façon prudente en envisageant l’ensemble des contextes possibles de son utilisation. Cette analyse des usages potentiels contribuera à guider la sélection du système employé. Cet examen permettra le traitement le plus équitable possible de tous les types de clientèles. Offrir aux membres du personnel des formations pour les sensibiliser à la discrimination et établir des protocoles d’aide à la décision sont d’autres solutions à envisager.</p>
    <h4>Maintenir la confiance</h4>
    <p>L’implantation de l’IA peut générer de l’inquiétude, au sein du personnel comme de la clientèle. Cette inquiétude peut s’exprimer, entre autres, par la crainte d’une utilisation défaillante ou mal intentionnée de l’IA. Une faible connaissance de cette technologie peut aussi soulever ces doutes.</p>
    <h5>La clientèle</h5>
    <p>La clientèle peut avoir peur des risques d’erreurs que présente un système basé sur l’IA. Cette méfiance peut s’expliquer lorsque l’IA est utilisée pour prendre des décisions importantes. Mentionnons, par exemple, l’admissibilité ou non à un prêt pour l’achat d’une maison. Une façon de rassurer la clientèle consiste à préciser l’usage qui est fait de l’IA et à faire intervenir un professionnel dans la prise de décision. Ces indications peuvent aussi aider la clientèle à faire des choix plus éclairés grâce à une meilleure compréhension des enjeux.</p>
    <p>La collecte de renseignements personnels peut également présenter des risques d’interférence avec le droit à la vie privée. Il convient ainsi de documenter les règles d’utilisation et de protection des données personnelles de la clientèle. Ces clarifications aideront à transmettre l’information plus facilement.</p>
    <p>Enfin, certaines personnes peuvent regretter l’absence de contact humain dans leurs échanges avec des agents conversationnels intelligents. Il peut donc être préférable de continuer à offrir en parallèle la possibilité d’interagir avec des personnes plutôt qu’avec des machines, ou à tout le moins de permettre de finaliser avec un professionnel la transaction engagée avec un agent conversationnel intelligent.</p>
    <h5>Le personnel</h5>
    <p>Le personnel peut avoir une certaine méfiance envers l’IA, surtout lorsque ce genre de technologie peut redéfinir ses façons de travailler. La direction doit adopter une attitude d’ouverture pour aider les personnes plus réticentes à exprimer leurs craintes et trouver ensuite des solutions à leurs inquiétudes. Ces solutions peuvent, par exemple, reposer sur le partage d’information et une démarche de formation.</p>
    <p>Ainsi, de manière générale, une implantation transparente de l’IA favorise l’établissement d’un lien de confiance avec la clientèle et le personnel.</p>
    <h4>Respecter la sphère privée</h4>
    <p>L’implantation de l’IA doit se faire de manière à observer le droit à la vie privée de la clientèle. Dans les secteurs de la finance et des assurances, plusieurs technologies nécessitent d’avoir accès à des données très nombreuses et personnelles. Citons, par exemple, l’incitation douce (nudging), soit l’utilisation d’outils connectés pour encourager le bon comportement des personnes assurées. Ce respect de la vie privée est également important lors du partage de données récoltées avec des tierces parties.</p>
    <p>Ainsi, la collecte et la diffusion de données personnelles devraient demeurer optionnelles. En effet, certaines personnes ne sont pas à l’aise de partager autant de données sur leur vie privée avec des entreprises. Un consentement éclairé de leur part est donc nécessaire avant de commencer à recueillir leurs données personnelles. De plus, une option de retrait devrait être facilement disponible pour les gens qui souhaitent quitter un programme après l’avoir essayé. Pour ces cas, des dispositions relatives au traitement des données déjà recueillies devraient aussi être prévues.</p>
    <h4>Les cinq principes éthiques à observer lors du recours à l’intelligence artificielle en finance</h4>
    <p>En novembre 2021, dans son rapport intitulé «&nbsp;:L’intelligence artificielle en finance, recommandations pour une utilisation responsable&nbsp;:», l’Autorité des marchés financiers a dégagé des principes directeurs pour lesquels il existe un consensus minimal. L’étude de 84 documents en éthique a permis de cibler les cinq principes suivants&nbsp;:</p>
    <ol>
      <li>La transparence et l’explicabilité</li>
      <li>La justice et l’équité</li>
      <li>La non-malfaisance et la sécurité</li>
      <li>La responsabilité et la reddition de comptes</li>
      <li>La vie privée</li>
    </ol>
    <h4>Les sept principes éthiques à observer pour utiliser l’IA de manière respectueuse envers les assurés</h4>
    <p>Cet extrait du rapport «&nbsp;:Intelligence artificielle, solidarité et assurances en Europe et au Canada&nbsp;:» publié par l’Institut de la technologie pour l’humain Montréal énumère sept principes éthiques&nbsp;:</p>
    <ul>
      <li><strong>Solidarité&nbsp;:</strong> le développement et l’utilisation de systèmes d’intelligence artificielle doivent être compatibles avec le maintien de liens de solidarité entre les personnes et les générations.</li>
      <li><strong>Équité&nbsp;:</strong> le développement et l’utilisation des systèmes d’intelligence artificielle doivent contribuer à la réalisation d’une société juste et équitable.</li>
      <li><strong>Responsabilité&nbsp;:</strong> le développement et l’utilisation des systèmes d’intelligence artificielle ne doivent pas contribuer à une déresponsabilisation des professionnels de l’assurance ni de leurs clients.</li>
      <li><strong>Transparence et justification&nbsp;:</strong> les systèmes d’intelligence artificielle doivent être intelligibles et leurs recommandations, justifiables et accessibles par les professionnels et les usagers.</li>
      <li><strong>Autonomie&nbsp;:</strong> l’utilisation de systèmes d’intelligence artificielle doit se faire dans le respect de l’autonomie des personnes et dans le but d’accroître la possibilité, pour les individus, de contrôler leur vie et leur environnement.</li>
      <li><strong>Vie privée et intimité&nbsp;:</strong> la vie privée et l’intimité doivent être protégées de l’intrusion des systèmes d’intelligence artificielle et de systèmes d’acquisition et d’archivage des données personnelles.</li>
      <li><strong>Bien-être&nbsp;:</strong> le déploiement de systèmes d’intelligence artificielle ne doit pas être préjudiciable aux personnes, professionnels et usagers auxquels le système s’applique et doit, dans la mesure du possible, contribuer à accroître leur bien-être.</li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "FinanceEtAssuranceEthics"
  }
</script>

<style scoped>

</style>