<template>
  <div :class="getToolTipPosition" :style="getToolTipStyle">
    <div class="d-flex">
      <div class="input-group">
        <span class="input-group-text">
          <span :style="currentColorClass(null)" />
        </span>
        <button
          class="btn btn-secondary chevron-button"
          :disabled="isDisabled"
          @click="isColorPanelOpened = !isColorPanelOpened"
        >
          <font-awesome-icon :icon="chevronType" />
        </button>
      </div>
      <button
        class="btn btn-secondary icon-button"
        :disabled="isDisabled"
        @click="highlightSelection()"
      >
        <font-awesome-icon
          icon="highlighter"
          style="color: rgba(var(--on-background-color), 0.87)"
        />
      </button>
      <button
        class="btn btn-secondary icon-button"
        :disabled="isDisabled"
        @click="openCommentPanelAndFocusTextArea($event)"
      >
        <font-awesome-icon
          v-if="comment === null"
          icon="comment"
          style="color: rgba(var(--on-background-color), 0.87)"
        />
        <font-awesome-icon
          v-else
          icon="comment"
          style="color: var(--primary-color)"
        />
      </button>
      <button
        class="btn btn-secondary icon-button"
        :disabled="isDisabled || highlightId === null"
        @click="deleteHighlight()"
      >
        <font-awesome-icon icon="times" style="color: var(--error-color)" />
      </button>
    </div>
    <div v-if="isColorPanelOpened" class="color-dropdown">
      <button
        v-for="color in colorList"
        :key="color"
        class="color-btn btn btn-secondary"
        @click="updateColor(color)"
      >
        <span class="color-element" :style="currentColorClass(color)" />
      </button>
    </div>
    <div v-if="isCommentPanelOpened" class="color-dropdown">
      <div>
        <textarea
          id="highlight-comment"
          v-model="comment"
          class="comment-area"
          placeholder="Entrez vos commentaires liés à ce surlignage"
          rows="2"
          cols="25"
        />
      </div>
      <button
        class="btn btn-success me-2 btn-action"
        :disabled="comment === null"
        @click="saveComment()"
      >
        {{ saveButtonText }}
      </button>
      <button
        class="btn btn-danger btn-action"
        :disabled="comment === null"
        @click="deleteComment()"
      >
        Effacer
      </button>
    </div>
  </div>
</template>

<script>
import highlightHelper from "@/helper/highlightHelper";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronUp,
  faTimes,
  faHighlighter,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters, mapMutations } from "vuex";
import _ from "lodash";

library.add({ faChevronDown, faChevronUp, faTimes, faHighlighter, faComment });

export default {
  name: "HighlightTooltip",
  props: {
    toolTipStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    highlightId: { type: String, default: "" },
    toolTipPosition: { type: String, default: "" },
  },
  emits: ["closeTooltip"],
  data() {
    return {
      colorList: ["gold", "red", "lime", "dodgerblue", "plum"],
      currentColor: null,
      comment: null,
      isColorPanelOpened: false,
      isCommentPanelOpened: false,
      selection: null,
      range: null,
    };
  },
  computed: {
    ...mapGetters(["getHighlight"]),
    isDisabled() {
      return _.isEmpty(this.toolTipStyle);
    },
    chevronType() {
      return this.isColorPanelOpened ? "chevron-up" : "chevron-down";
    },
    getToolTipStyle() {
      if (this.isColorPanelOpened) {
        return {
          top: this.toolTipStyle.top - 38 + "px",
          opacity: this.toolTipStyle.opacity,
          left: this.toolTipStyle.left,
        };
      }
      if (this.isCommentPanelOpened) {
        if (this.toolTipPosition === "left") {
          return {
            top: this.toolTipStyle.top - 117.5 + "px",
            opacity: this.toolTipStyle.opacity,
            left: this.toolTipStyle.left - 106 + "px",
          };
        }
        if (this.toolTipPosition === "right") {
          return {
            top: this.toolTipStyle.top - 117.5 + "px",
            opacity: this.toolTipStyle.opacity,
            left: this.toolTipStyle.left + "px",
          };
        }
        return {
          top: this.toolTipStyle.top - 117.5 + "px",
          opacity: this.toolTipStyle.opacity,
          left: this.toolTipStyle.left - 47 + "px",
        };
      }
      return {
        top: this.toolTipStyle.top + "px",
        opacity: this.toolTipStyle.opacity,
        left: this.toolTipStyle.left + "px",
      };
    },
    getToolTipPosition() {
      if (this.toolTipPosition === "left") {
        return "tooltip-left";
      }
      if (this.toolTipPosition === "right") {
        return "tooltip-right";
      }
      return "tooltip-top";
    },
    saveButtonText() {
      if (this.highlightId === null) {
        return "Sauvegarder et Surligner";
      }
      return "Sauvegarder";
    },
  },
  watch: {
    highlightId(val) {
      this.isCommentPanelOpened = false;
      this.isColorPanelOpened = false;
      this.comment = null;
      if (val != null) {
        let currentHighlight = this.$store.getters.getHighlight(
          parseInt(val.substring(10))
        );
        this.currentColor = currentHighlight.color;
        this.comment = currentHighlight.comment;
      } else {
        this.currentColor = this.colorList[0];
      }
    },
    toolTipStyle() {
      this.comment = null;
      this.isCommentPanelOpened = false;
      this.isColorPanelOpened = false;
      this.currentColor = this.colorList[0];
    },
  },
  mounted() {
    this.currentColor = this.colorList[0];
  },
  methods: {
    ...mapMutations(["updateHighlight", "deleteHighlightComment"]),
    updateColor(color) {
      this.currentColor = color;
      this.isColorPanelOpened = !this.isColorPanelOpened;
    },
    saveComment() {
      this.isCommentPanelOpened = !this.isCommentPanelOpened;
      if (this.highlightId === null) {
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(this.range);
      }
      this.highlightSelection();
    },
    deleteComment() {
      this.comment = null;
      if (this.highlightId !== null) {
        this.highlightSelection();
      }
    },
    openCommentPanelAndFocusTextArea(e) {
      this.isCommentPanelOpened = !this.isCommentPanelOpened;
      e.target.blur();
      if (this.highlightId === null) {
        this.selection = getSelection();
        this.range = getSelection().getRangeAt(0);
      }
      return true;
    },
    currentColorClass(color = null) {
      return {
        backgroundColor: color != null ? color : this.currentColor,
        width: "1rem",
        height: "1rem",
        border:
          color != null
            ? "1px solid var(--bg-color-09dp)"
            : "1px solid var(--bg-color-05dp)",
        display: "inline-block",
      };
    },
    highlightSelection() {
      if (this.highlightId !== null) {
        highlightHelper.highlight(
          this.$route.name,
          this.currentColor,
          this.comment,
          parseInt(this.highlightId.substring(10)),
          null,
          true
        );
      } else {
        highlightHelper.highlight(
          this.$route.name,
          this.currentColor,
          this.comment,
          null,
          null,
          false
        );
      }
      this.clearSelection();
      document.getElementById("main-content").click();
      if (this.isColorPanelOpened) {
        this.isColorPanelOpened = !this.isColorPanelOpened;
      }
      if (this.isCommentPanelOpened) {
        this.isCommentPanelOpened = !this.isCommentPanelOpened;
      }
      this.$emit("closeTooltip");
    },
    deleteHighlight() {
      highlightHelper.deleteHighlight(this.highlightId);
      this.clearSelection();
      document.getElementById("main-content").click();
      if (this.isColorPanelOpened) {
        this.isColorPanelOpened = !this.isColorPanelOpened;
      }
      if (this.isCommentPanelOpened) {
        this.isCommentPanelOpened = !this.isCommentPanelOpened;
      }
      this.$emit("closeTooltip");
    },
    clearSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    },
  },
};
</script>

<style scoped>
.tooltip-top,
.tooltip-left,
.tooltip-right {
  background-color: var(--bg-color-03dp);
  color: rgba(var(--on-background-color), 0.87);
  border: var(--bg-color-09dp) 1px solid;
  border-radius: 0.25rem;
  display: inline-block;
  margin-bottom: 15px;
  opacity: 0;
  padding: 0.5rem;
  position: absolute;
}

.tooltip-left:after {
  border-top: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid transparent 10px;
  border-left: solid var(--bg-color-03dp) 10px;
  bottom: 15px;
  content: " ";
  height: 0;
  left: 100%;
  position: absolute;
  width: 0;
}

.tooltip-right:after {
  border-top: solid transparent 10px;
  border-left: solid transparent 10px;
  border-bottom: solid transparent 10px;
  border-right: solid var(--bg-color-03dp) 10px;
  bottom: 15px;
  content: " ";
  height: 0;
  left: -20px;
  position: absolute;
  width: 0;
}

.tooltip-top:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid var(--bg-color-03dp) 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 44%;
  position: absolute;
  width: 0;
}

.color-btn {
  background-color: var(--bg-color-07dp);
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.color-dropdown > * > .color-btn,
.color-dropdown > .color-btn:not(:last-of-type) {
  margin-right: 0.25rem;
}

.color-element {
  float: left;
}

.color-dropdown {
  padding: 0.75rem 0.75rem 0 0.75rem;
  text-align: center;
}

.icon-button {
  margin-left: 0.25rem;
  background-color: var(--bg-color-07dp);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.regular-icon {
  color: rgba(var(--on-background-color), 0.87);
}

.red-icon {
  color: rgba(var(--error-color), 0.87);
}

.blue-icon {
  color: rgba(var(--secondary-color), 0.87);
}

.chevron-button {
  background-color: var(--bg-color-07dp);
  color: rgba(var(--on-background-color), 0.87);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.input-group-text {
  background-color: var(--bg-color-07dp);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border: solid #6c757d 1px;
}

.comment-area {
  border-radius: 0.25rem;
  padding: 0.5rem;
  resize: none;
}

.btn-action {
  font-size: smaller;
}
</style>
