<template>
  <div class="accordion-item">
    <h2 id="headingHighlights" class="accordion-header">
      <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHighlights" aria-expanded="false" aria-controls="collapseHighlights" @click="closePickerIfOpen()">
        {{ $t("highlightList") }}
      </button>
    </h2>
    <div id="collapseHighlights" class="accordion-collapse collapse" aria-labelledby="headingHighlights" data-bs-parent="#accordionTools">
      <div :class="accordionBodyClass">
          <table v-if="highlightList.length > 0" class="table table-striped align-middle" aria-label="Highlights">
            <thead>
            <tr>
              <th scope="col">{{ $t("content") }}</th>
              <th scope="col">{{ $t("page") }}</th>
              <th scope="col">{{ $t("color") }}</th>
              <th scope="col">{{ $t("comment") }}</th>
              <th scope="col"></th>
              <th v-if="isColorPanelOpened" scope="col"></th>
              <th v-if="isCommentPanelOpened" scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(highlight, index) in highlightList" :key="highlight">
              <td>{{ stripContentIfTooLong(highlight.content) }}</td>
              <td><router-link :to="{name: highlight.route}">{{ $t(highlight.route) }}</router-link></td>
              <td><span :style="currentColorClass(highlight.color)"/></td>
              <td>{{ stripContentIfTooLong(highlight.comment) }}</td>
              <td>
                <button class="btn icon-btn" @click="openColorPickerForIndex(index)"><font-awesome-icon icon="highlighter"/></button>
                <button v-if="highlight.comment !== '' && highlight.comment !== null" class="btn icon-btn" @click="openCommentPanelForIndex(index)"><font-awesome-icon icon="comment" style="color: var(--primary-color);"/></button>
                <button v-else class="btn icon-btn" @click="openCommentPanelForIndex(index)"><font-awesome-icon icon="comment"/></button>
                <button class="btn icon-btn" @click="deleteHighlight(highlight)"><font-awesome-icon icon="times" style="color: var(--error-color);"/></button>
              </td>
              <td v-if="isColorPanelOpened && openedIndex === index" class="color-dropdown">
                <button v-for="color in colorList" :key="color" type="button" class="color-btn btn" @click="update(highlight, color)"><span class="color-element" :style="currentColorClass(color)"/></button>
              </td>
              <td v-else :style="isColorPanelOpened ? '' : 'display: none;'"></td>
              <td v-if="isCommentPanelOpened && openedIndex === index">
                <textarea v-model="tempComment" class="comment-area" rows="2" cols="15" placeholder="Entrez vos commentaires liés à ce surlignage"></textarea>
                <div class="d-flex justify-content-center">
                  <button class="btn btn-success me-2 btn-action" :disabled="tempComment === null" @click="update(highlight, highlight.color)">Sauvegarder</button>
                  <button class="btn btn-danger btn-action" :disabled="tempComment === null" @click="deleteComment(highlight)">Effacer</button>
                </div>
              </td>
              <td v-else :style="isCommentPanelOpened ? '' : 'display: none;'"></td>
            </tr>
            </tbody>
          </table>
        <p v-else>Vous n'avez pas encore enregistré de surlignage.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faHighlighter, faComment } from '@fortawesome/free-solid-svg-icons'

library.add({ faTimes, faHighlighter, faComment })

export default {
  name: "HighlightList",
  data() {
    return {
      colorList: [
        'gold',
        'red',
        'lime',
        'dodgerblue',
        'plum'
      ],
      isColorPanelOpened: false,
      isCommentPanelOpened: false,
      openedIndex: null,
      tempComment: null
    }
  },
  computed: {
    ...mapGetters([
      'getHighlightList', 'getTheme'
    ]),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
    highlightList() {
      return this.getHighlightList;
    },
    tableClass() {
      let theme = localStorage.getItem("theme");
      if(theme === 'dark') {
        return 'table table-dark table-striped align-middle';
      }
      return 'table table-striped align-middle';
    },
  },
  methods: {
    ...mapMutations([
      'removeElementFromHighlightList',
      'updateHighlight',
      'deleteHighlightComment'
    ]),
    currentColorClass(color = null) {
      return {
        backgroundColor: color != null ? color : this.currentColor,
        width: '1rem',
        height: '1rem',
        border: color != null ? '1px solid var(--bg-color-09dp)' : '1px solid var(--bg-color-05dp)',
        display: 'inline-block'
      }
    },
    stripContentIfTooLong(content) {
      if(content !== null && content.length > 30) {
        return content.substring(0, 30) + '...'
      }
      return content
    },
    deleteHighlight(highlight) {
      this.removeElementFromHighlightList(highlight);
    },
    openColorPickerForIndex(index) {
      this.tempComment = this.highlightList[index].comment;
      this.isColorPanelOpened = !this.isColorPanelOpened;
      this.openedIndex = index;
    },
    openCommentPanelForIndex(index) {
      this.isCommentPanelOpened = !this.isCommentPanelOpened;
      this.openedIndex = index;
      this.tempComment = this.highlightList[index].comment;
    },
    update(highlight, color) {
      this.updateHighlight({
        highlight: highlight,
        color: color,
        comment: this.tempComment})
    },
    deleteComment(highlight) {
      this.deleteHighlightComment({highlight: highlight})
      this.tempComment = null;
    },
    closePickerIfOpen() {
      if(this.isCommentPanelOpened) {
        this.isCommentPanelOpened = false
      }
      if(this.isColorPanelOpened) {
        this.isColorPanelOpened = false
      }
      this.tempComment = null
    }
  }
}
</script>

<style scoped>

.table {
  text-align: center;
}

.icon-btn {
  padding: 0 0.1rem 0 0.1rem;
}

.color-btn {
  padding: 0 0.1rem 0 0.1rem;
}

.color-element {
  border: var(--bg-color-09dp) 1px solid;
}

.comment-area {
  resize: none;
  width: 75%;
  height: 5rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.btn-action {
  font-size: smaller;
}

thead {
  background: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: rgba( var(--accordion-bg-color), 0.8);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

</style>