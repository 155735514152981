<template>
  <h1 class="content-title"> {{ $t('financeEtAssuranceHome') }} </h1>
  <div class="mb-4 mt-4">
    <p>Plusieurs cas d’usage des technologies de l’IA dans le secteur de la finance et de l’assurance sont en devenir. Un bon nombre de clients ont déjà reçu des services qui recourent à l’intelligence artificielle sans le savoir. L’IA pourrait devenir monnaie courante pour vos opérations.</p>
    <p>Vous êtes invité à utiliser le livre numérique pour noter vos idées ou vos réflexions, ou encore pour surligner un extrait de texte particulièrement pertinent à vos yeux.</p>
  </div>
</template>

<script>
export default {
  name: "FinanceEtAssuranceHome"
}
</script>

<style scoped>

</style>