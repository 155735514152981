<template>
  <h1 class="content-title">Les impacts de l’intelligence artificielle sur le modèle d’affaires en santé</h1>
  <div class="mb-4 mt-4">
    <p>L’utilisation de l’IA dans le secteur de la santé a des impacts sur le modèle d’affaires. Voici des questions clés qui peuvent guider la réflexion des professionnels de la santé et des gestionnaires quant à l’implantation des technologies de l’IA.</p>
    <h4>La segmentation des patients</h4>
    <ul>
      <li>Grâce à l’IA, aimeriez-vous acquérir une meilleure connaissance des patients qui ont recours à vos services&nbsp;? Qui sont-ils&nbsp;? Quel âge ont-ils&nbsp;? Où habitent-ils&nbsp;? Quels sont leurs besoins&nbsp;? Quels problèmes rencontrent-ils&nbsp;? Que recherchent-ils ? Quelles caractéristiques communes partagent-ils&nbsp;?</li>
      <li>Aimeriez-vous pouvoir mieux anticiper l’affluence dans les urgences, les cliniques médicales, les CISSS&nbsp;(centres intégrés de santé et de services sociaux) à l’aide de l’IA&nbsp;?</li>
      <li>Avez-vous besoin d’analyses prédictives sur l’évolution des besoins des patients&nbsp;?</li>
      <li>Savez-vous comment les patients perçoivent l’IA en santé&nbsp;?</li>
    </ul>
    <h4>La distribution des services médicaux</h4>
    <ul>
      <li>Désirez-vous mieux informer la population sur les soins et les services que vous offrez&nbsp;?</li>
      <li>L’IA peut-elle vous aider à planifier et à organiser plus de consultations en présentiel, en télémédecine, par visioconférence, par téléphone&nbsp;? Quels sont vos besoins en ce sens&nbsp;?</li>
    </ul>
    <h4>La confiance des patients et la protection des données</h4>
    <ul>
      <li>Avez-vous le consentement des patients pour récolter des données sur leur état de santé&nbsp;?</li>
      <li>Quelles données récoltez-vous&nbsp;? Quelle méthode de collecte de données adoptez-vous&nbsp;?</li>
      <li>Les données sont-elles stockées sur des serveurs sécurisés&nbsp;?</li>
      <li>Des organisations extérieures ont-elles accès à ces données, comme des fournisseurs ou des partenaires du réseau&nbsp;?</li>
    </ul>
    <h4>Les ressources clés</h4>
    <ul>
      <li>Quels professionnels de la santé offrent des services à l’aide de l’IA&nbsp;?</li>
      <li>Vous manque-t-il un équipement qui serait indispensable à l’intégration de l’IA&nbsp;? Avez-vous approché des conseillers ou des centres de recherche en IA pour vous aider dans votre projet&nbsp;?</li>
      <li>Avez-vous calculé les ressources financières nécessaires à l’implantation de l’IA&nbsp;? Connaissez-vous le retour sur l’investissement que vous comptez faire en IA&nbsp;?</li>
    </ul>
    <h4>Les partenaires clés</h4>
    <ul>
      <li>Des partenaires actuels, anciens ou nouveaux peuvent-ils vous aider à explorer des solutions innovantes en IA&nbsp;(investisseurs, banques, hôpitaux, fournisseurs, conseillers, organismes gouvernementaux, centres de recherche, cliniques médicales, CISSS, services ambulanciers)&nbsp;? Votre organisation pourrait-elle innover avec eux&nbsp;?</li>
      <li>Quelles informations pourraient être partagées avec ces partenaires&nbsp;? Quels intérêts ce partage d’informations servirait-il&nbsp;?</li>
      <li>Comment vos partenaires perçoivent-ils l’IA&nbsp;?</li>
    </ul>
    <h4>La proposition de valeur</h4>
    <ul>
      <li>Aimeriez-vous mieux personnaliser vos services et vos soins pour répondre aux besoins particuliers des patients&nbsp;?</li>
      <li>Quels problèmes ou besoins désirez-vous traiter grâce à l’IA&nbsp;?</li>
      <li>Cela vous rassurerait-il de savoir que les patients peuvent avoir accès à vos services plus rapidement et recevoir des soins de meilleure qualité grâce à l’IA&nbsp;?</li>
    </ul>
    <h4>La relation client</h4>
    <ul>
      <li>L’IA peut faire certains suivis de façon autonome auprès de patients. Est-ce une pratique que vous désirez implanter&nbsp;?</li>
      <li>Envisageriez-vous d’utiliser des robots conversationnels pour répondre plus rapidement aux questions administratives des patients&nbsp;?</li>
    </ul>
    <h4>Les revenus</h4>
    <ul>
      <li>Aimeriez-vous répondre à plus de demandes de soins grâce à l’IA&nbsp;?</li>
      <li>L’IA pourrait-elle vous permettre d’offrir de nouveaux services que vous ne pouvez pas offrir actuellement&nbsp;? Quels services aimeriez-vous ajouter à votre offre&nbsp;?</li>
      <li>Aimeriez-vous accroître vos sources de revenus grâce à l’IA&nbsp;?</li>
    </ul>
    <h4>Les activités clés</h4>
    <ul>
      <li>Pouvez-vous personnaliser les soins grâce à l’IA&nbsp;?</li>
      <li>Quels services ou soins l’automatisation pourrait-elle soutenir&nbsp;?</li>
      <li>Avez-vous besoin de mieux planifier les achats matériels et l’organisation des soins&nbsp;?</li>
    </ul>
    <h4>La structure de coûts</h4>
    <ul>
      <li>Avez-vous besoin d’aide pour mieux planifier l’achat d’équipements et de matériels, et peut-être même pour en réduire le coût&nbsp;?</li>
      <li>Aimeriez-vous automatiser des tâches afin de réduire des coûts de main-d’œuvre ou de faire davantage avec moins&nbsp;?</li>
      <li>Aimeriez-vous concevoir des horaires de travail optimaux qui réduiraient les temps morts et optimiseraient les coûts&nbsp;?</li>
    </ul>
    <h4>La gestion du changement</h4>
    <ul>
      <li>Votre équipe médicale est-elle prête à adopter les changements apportés par l’IA&nbsp;?</li>
      <li>Qui bénéficierait d’une formation continue pour bien accueillir ces changements ? Pouvez-vous réaffecter des professionnels de la santé à des tâches plus importantes ?</li>
      <li>Les ressources matérielles actuelles permettent-elles d’adopter ces changements&nbsp;?</li>
      <li>Êtes-vous à l’aise avec l’idée qu’une technologie en IA aide à la prise de décisions&nbsp;?</li>
      <li>Envisageriez-vous de remplacer des technologies qui ne sont pas performantes pour supporter la transition vers l’IA&nbsp;?</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SanteImpacts"
}
</script>

<style scoped>

</style>