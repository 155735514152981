<template>
  <h1 class="content-title">Les impacts de l'intelligence artificielle sur le modèle d’affaires en finance et en assurance</h1>
  <div class="mb-4 mt-4">
    <p>Les technologies de l’IA dans les secteurs de la finance et de l’assurance permettent d’innover à l’intérieur comme à l’extérieur de l’organisation. Voici des questions clés pour guider une réflexion sur l’implantation des technologies de l’IA.</p>
    <h4>La segmentation des clients</h4>
    <ul>
      <li>Pourriez-vous développer une meilleure connaissance de vos clients grâce à l’IA&nbsp;? Qui sont-ils&nbsp;? Quel âge ont-ils&nbsp;? Quels sont leurs besoins, leurs problèmes, leurs préférences&nbsp;? Pourriez-vous personnaliser les produits et les services&nbsp;?</li>
      <li>Qui sont les clients «&nbsp;cibles&nbsp;»&nbsp;?</li>
      <li>Pour quels clients souhaitez-vous créer de la valeur grâce à l’IA&nbsp;?</li>
    </ul>
    <h4>Les canaux de distribution</h4>
    <ul>
      <li>Pourriez-vous mieux joindre les clients et communiquer avec eux grâce à l’IA&nbsp;?</li>
      <li>Est-ce qu’un système de recherche intelligent pourrait aider les clients à trouver plus facilement des renseignements sur vos produits&nbsp;?</li>
    </ul>
    <h4>La confiance des clients et la protection des données</h4>
    <ul>
      <li>Quelles données récoltez-vous en ce moment et lesquelles souhaitez-vous récolter&nbsp;?</li>
      <li>Les données sont-elles stockées sur des serveurs sécurisés&nbsp;? Vos fournisseurs peuvent-ils partager les données de votre organisation&nbsp;?</li>
      <li>Qui a accès à ces données&nbsp;?</li>
      <li>Quels sont les droits des clients quant à la protection de leurs renseignements personnels&nbsp;? Savent-ils quelles données vous récoltez&nbsp;? Ont-ils la possibilité de refuser de partager des renseignements&nbsp;?</li>
    </ul>
    <h4>Les ressources clés</h4>
    <ul>
      <li>Quelles ressources matérielles, financières, intellectuelles et humaines le projet d’intelligence artificielle nécessite-t-il&nbsp;?</li>
      <li>Avez-vous calculé le retour sur l’investissement de votre projet en IA&nbsp;?</li>
    </ul>
    <h4>Les partenaires clés</h4>
    <ul>
      <li>D’anciens, d’actuels ou de nouveaux partenaires peuvent-ils vous aider à explorer des solutions innovantes en IA&nbsp;? À trouver les ressources dont vous ne disposez pas&nbsp;?</li>
      <li>Pourriez-vous partager des informations avec ces partenaires&nbsp;? Quels types d’informations&nbsp;? Dans quel but&nbsp;? Quel est l’intérêt de ce partage d’informations pour eux et pour vous&nbsp;?</li>
      <li>Comment vos partenaires perçoivent-ils l’IA&nbsp;? Envisageriez-vous d’élaborer un plan de communication sur l’adoption de l’IA&nbsp;?</li>
    </ul>
    <h4>La proposition de valeur</h4>
    <ul>
      <li>Quelle valeur pouvez-vous créer grâce à l’IA&nbsp;?</li>
      <li>Pourriez-vous utiliser l’IA pour vous démarquer de la concurrence&nbsp;? Savez-vous à quel stade technologique vos concurrents se situent&nbsp;? Sont-ils à l’affût de vos nouvelles démarches&nbsp;?</li>
      <li>L’IA apportera-t-elle des solutions pour répondre aux besoins des clients et résoudre leurs problèmes&nbsp;?</li>
      <li>Qu’est-ce que l’IA procurera de plus aux clients&nbsp;? Quels sont les avantages de ces produits et services&nbsp;?</li>
    </ul>
    <h4>La relation client</h4>
    <ul>
      <li>Quel type de relation les clients souhaitent-ils avoir avec vous&nbsp;? Une relation de type transactionnel&nbsp;? Axée sur le conseil&nbsp;?</li>
      <li>Qui engage la relation&nbsp;? Le client&nbsp;? Vous&nbsp;?</li>
      <li>L’IA vous permettrait-elle d’établir la relation souhaitée avec vos clients&nbsp;?</li>
      <li>Envisagez-vous d’utiliser des robots conversationnels ou d’automatiser certaines activités&nbsp;?</li>
      <li>Comment informerez-vous les clients de vos initiatives en IA&nbsp;? Savez-vous s’ils perçoivent l’IA de façon négative ou positive&nbsp;? Adopterez-vous un plan de communication stratégique sur le sujet&nbsp;?</li>
    </ul>
    <h4>Les revenus</h4>
    <ul>
      <li>L’IA vous permettra-t-elle de revoir les manières de générer des revenus&nbsp;?</li>
      <li>Pouvez-vous revoir les modes de paiement grâce à l’IA (p. ex. mensuel, annuel, à la pièce, à forfait, avec prime)&nbsp;?</li>
      <li>Quels produits ou services vous rapporteraient le plus grâce à l’IA&nbsp;?</li>
    </ul>
    <h4>Les activités clés</h4>
    <ul>
      <li>Quelles activités peuvent être créées ou améliorées grâce à l’IA pour répondre à la proposition de valeur&nbsp;?</li>
      <li>Quelles activités clés l’automatisation pourrait-elle accélérer&nbsp;? La gestion des données&nbsp;? Les tâches administratives&nbsp;? La gestion des produits et services&nbsp;? Le service à la clientèle&nbsp;? La production de rapports pour faciliter la prise de décisions&nbsp;?</li>
    </ul>
    <h4>La structure de coûts</h4>
    <ul>
      <li>Désirez-vous optimiser vos processus opérationnels&nbsp;?</li>
      <li>L’automatisation de tâches administratives ou opérationnelles vous permettra-t-elle de réduire vos coûts de main-d’œuvre ou de pallier un manque de main-d’œuvre&nbsp;?</li>
    </ul>
    <h4>La gestion du changement</h4>
    <ul>
      <li>Est-ce que vous ou vos employés êtes prêts à adopter les changements apportés par l’IA&nbsp;? Qui bénéficierait d’une formation continue pour bien accueillir ces changements&nbsp;?</li>
      <li>Vos ressources matérielles vous permettent-elles d’adopter ces changements&nbsp;? Quels sont vos besoins&nbsp;?</li>
      <li>Êtes-vous à l’aise avec l’idée qu’une machine vous aide à la prise de décisions&nbsp;?</li>
      <li>Envisageriez-vous de remplacer des technologies qui sont moins performantes par des technologies faisant appel à l’IA&nbsp;?</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FinanceEtAssuranceImpacts"
}
</script>

<style scoped>

</style>