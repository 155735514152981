<template>
  <h1 class="content-title">Les enjeux éthiques de l’intelligence artificielle dans la vente au détail</h1>
  <div class="mb-4 mt-4">
    <p>L’IA dans la vente au détail soulève des enjeux éthiques. Voici des enjeux éthiques potentiels et quelques pistes de solution à considérer avec un conseiller-expert.</p>
    <h3>Réorganiser le travail et limiter les pertes d’emplois</h3>
    <p>L’IA réduit le nombre de postes nécessaires pour atteindre une productivité équivalente ou supérieure. L’introduction de robots conversationnels au service à la clientèle et la robotisation de la manutention peuvent répondre à une pénurie de main-d’œuvre.</p>
    <p>Une diminution de l’effectif peut toutefois entraîner la fermeture de points de service et causer des pertes d’emplois (p. ex. le soutien administratif propre au fonctionnement d’une succursale).</p>
    <p>L’IA requiert cependant souvent une supervision humaine. Elle peut être envisagée comme une mesure d’appui aux membres du personnel pour accroître leur productivité. La transition vers l’IA peut être facilitée grâce à la réorganisation du travail et à la formation des employés.</p>
    <h3>Assurer la cybersécurité</h3>
    <p>Pour utiliser l’IA, les commerces doivent informatiser leur processus opérationnel (processus liés à la gestion des marchandises, la facturation, la comptabilité…). Le recours à l’IA implique donc de manipuler un grand nombre de données, lesquelles sont parfois de nature très sensible (p. ex. numéros de cartes de crédit, adresses).</p>
    <p>La nature sensible de ces données leur confère une valeur considérable. Le commerce qui est en possession de ce type de données doit faire preuve de prudence et se prémunir contre une attaque informatique. Il arrive que des pirates informatiques réclament le paiement d’une rançon en échange de données volées. Outre des pertes financières, ce genre d’attaque présente aussi le risque de briser le lien de confiance entre l’entreprise, sa clientèle et ses fournisseurs.</p>
    <p>Pour se protéger de ces attaques, il est de mise de vérifier la robustesse du niveau de sécurité du système informatique et de corriger toute faille identifiée. Par la suite, les solutions adoptées exigeront des mises à jour régulières afin de garantir la sécurité constante de l’environnement informatique.</p>
    <h3>Préserver la responsabilité et l’imputabilité humaine</h3>
    <p>Malgré ses capacités impressionnantes, l’IA n’est pas sans faille. Cette technologie requiert une supervision humaine.</p>
    <p>Des erreurs peuvent être commises par une caisse automatique ou lors de la prise de décisions stratégiques d’ordre commercial. Ces erreurs doivent être imputables à une personne en mesure de les corriger. La clientèle ou le partenaire d’affaires qui subit le préjudice demandera probablement des explications, et il sera difficile d’en fournir si aucun professionnel n’encadre les tâches accomplies par l’IA.</p>
    <p>Le fait d’assurer un minimum de supervision humaine à l’utilisation de l’IA préservera donc la responsabilité et l’imputabilité des employés. Ils pourront vérifier le travail de l’IA et offrir la garantie que les actions posées sont adaptées au contexte donné.</p>
    <p>Dans cette optique, l’utilisation de systèmes qui fonctionnent de manière plus transparente peut faciliter la compréhension des décisions prises par l’IA. Pour encadrer davantage les prises de décisions du système, il peut être utile de définir à l’avance les critères sur lesquels reposent ses choix. De même, il peut être pertinent d’établir quels types d’erreurs et quelles marges d’erreur sont raisonnables, selon leur origine (humaine ou informatique).</p>
    <p>Établir ces critères au préalable permet de faciliter la gestion des situations provoquées par ces erreurs. Affecter une équipe à l’analyse de ce type de situations peut être un ajout intéressant au sein de l’organisation.</p>
    <h3>Éviter la présence de biais</h3>
    <p>Les données employées pour entraîner les technologies basées sur l’IA sont produites et traitées par des humains. Comme les humains ne sont pas immunisés contre les biais et les erreurs, il se peut que les données fournies aux systèmes d’IA soient biaisées ou inexactes. Au cours de leur entraînement, ces systèmes apprendront alors à reproduire les erreurs et les biais inhérents aux données.</p>
    <p>Par exemple, l’utilisation de caméras intelligentes qui font de la reconnaissance faciale peut favoriser une meilleure sécurité des commerces et des entrepôts. Par contre, si les algorithmes de reconnaissance faciale sont moins performants avec les visages de personnes noires qu’avec ceux de personnes blanches, ce défaut de précision peut augmenter le risque de profilage racial.</p>
    <p>Ainsi, il est préférable de réfléchir aux façons dont un système d’IA pourrait être biaisé. En effet, les données utilisées pour l’entraînement sont rarement montrées. De plus, le raisonnement derrière les décisions prises par le système d’IA peut souvent manquer de transparence.</p>
    <p>Un examen prudent des usages éventuels de l’IA contribuera donc à collecter des données de qualité et à appliquer des raisonnements qui favorisent le traitement le plus équitable possible de la clientèle, du personnel et des partenaires commerciaux. La mise en place de formations pour sensibiliser le personnel à la discrimination que l’IA pourrait causer est une autre option intéressante.</p>
    <h3>Maintenir la confiance</h3>
    <p>Comme l’IA est un type de technologie somme toute encore récent, il est normal que son implantation provoque de la méfiance. Le personnel, la clientèle et les partenaires commerciaux peuvent, par exemple, craindre un usage mal intentionné ou s’inquiéter au sujet de la fiabilité du système utilisé. Adopter un plan de communication permettra de rassurer le personnel, les clients et les partenaires commerciaux.</p>
    <h4>La clientèle</h4>
    <p>L’utilisation de robots conversationnels peut améliorer le service à la clientèle. Toutefois, certaines personnes peuvent regretter la perte du contact humain dans leurs interactions. Elles peuvent aussi éprouver de la frustration lorsque l’agent conversationnel ne comprend pas leur requête ou les dirige vers un autre service. Pour les personnes attachées à la confidentialité de leur demande, la possibilité que leurs messages soient conservés par la compagnie peut également être une source d’inquiétude. Il devient ainsi plus difficile de développer un lien de confiance. Informer la clientèle des usages de l’IA et de la manière dont les données sont utilisées et protégées contribuera à préserver sa confiance dans l’entreprise. De plus, la possibilité de discuter avec une personne du service à la clientèle pourra aussi satisfaire les individus qui préfèrent cette option.</p>
    <h4>Le personnel</h4>
    <p>L’implantation de l’IA peut susciter des réticences au sein du personnel. L’adoption d’une attitude bienveillante encouragera les membres du personnel à exprimer leurs inquiétudes. Vous serez alors en mesure de répondre à leurs appréhensions et d’apporter des solutions. Le partage de documentation et l’offre de formations comptent parmi les solutions à envisager. Pour réussir à maintenir et à solidifier le lien de confiance, il est préférable d’agir avec le plus de transparence et d’ouverture possible.</p>
    <h3>Respecter la sphère privée</h3>
    <p>L’utilisation de l’IA à des fins de marketing offre des solutions prometteuses. Ces solutions reposent, en partie, sur la collecte de données personnelles. Grâce à une connaissance fine des caractéristiques de chaque personne, il est possible de diffuser des publicités plus ciblées.</p>
    <p>La collecte de données personnelles soulève toutefois des questions relatives à la propriété de ces renseignements. Ces questions se complexifient pour les entreprises qui partagent l’information récoltée auprès de tierces parties. De plus, la collecte d’informations personnelles interfère avec le droit à la confidentialité et à la vie privée. Le traitement de ces données permet effectivement de caractériser les individus avec une précision qui dépasse leur propre connaissance d’eux-mêmes.</p>
    <p>La collecte de données doit être conditionnelle à l’obtention d’un consentement éclairé de la part de chaque individu. Une partie de la clientèle peut éprouver un malaise à l’idée que des commerces ont accès à ses informations personnelles. En offrant la possibilité de ne pas participer à une collecte de données et de faire effacer ses informations, l’entreprise permet aux individus de faire des choix. Il est préférable que l’usage de technologies qui reposent sur la collecte, la diffusion et l’analyse de renseignements personnels demeure optionnel.</p>
    <h3>Conserver un équilibre entre l’expérience personnalisée et l’incitation à la surconsommation</h3>
    <p>Les algorithmes basés sur l’IA sont très utiles pour faire des recommandations personnalisées de produits sur des plateformes de commande en ligne. En effet, ils analysent les comportements de consommation de la clientèle afin de créer des profils d’achat. Grâce à ces profils, ils peuvent faire des propositions d’articles adaptés aux goûts et aux besoins de chaque personne.</p>
    <p>Cette personnalisation simplifie et accélère l’expérience d’achat en ligne. Elle peut se solder par une augmentation des revenus de ventes. Cela dit, en favorisant l’achat de produits que les consommateurs ne prévoyaient pas acquérir, les systèmes de recommandation peuvent aussi contribuer à encourager la surconsommation.</p>
    <p>Dans le contexte actuel où la population est incitée à diminuer son empreinte écologique, il est prudent de réfléchir à la façon dont sont utilisés les algorithmes de recommandation. Par exemple, il serait possible d’établir une limite au-delà de laquelle des suggestions d’articles ne s’afficheraient plus.</p>
  </div>
</template>

<script>
export default {
  name: "VenteAuDetailEthics"
}
</script>

<style scoped>

</style>