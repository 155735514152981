<template>
  <h1 class="content-title">Les enjeux éthiques de l’intelligence artificielle pour l'industrie manufacturière</h1>
  <div class="mb-4 mt-4">
    <h2></h2>
    <p>Si l’intégration d’applications de l’IA au domaine manufacturier offre de belles perspectives, elle soulève aussi des enjeux éthiques. Une bonne compréhension de ces enjeux permettra de mieux planifier l’implantation de l’IA. Voici les principaux enjeux éthiques à entrevoir et quelques avenues à explorer pour y faire face.</p>
    <h4>Limiter les pertes d’emplois et réorganiser le travail</h4>
    <p>L’IA peut aider à réduire les coûts de production en optimisant le travail, ce qui se traduit parfois par une baisse du nombre d’employés nécessaires pour atteindre une productivité équivalente ou supérieure.</p>
    <p>Cependant, l’IA n’est pas forcément synonyme d’une suppression de postes ou d’emplois. En effet, elle permet d’automatiser des tâches comme&nbsp;:</p>
    <ul>
      <li>le tri, le découpage ou l’assemblage de pièces&nbsp;;</li>
      <li>le ramassage ou le déplacement d’objets&nbsp;;</li>
      <li>l’inspection visuelle (contrôle de la qualité).</li>
    </ul>
    <p>Comme ces tâches exigent une supervision, entre autres choses, une réorganisation du travail permettra aux employés de réaliser des tâches à valeur ajoutée. L’IA apporte aussi un soutien à la prise de décision des employés grâce à ses analyses des procédés administratifs, par exemple la prévision des achats, des ventes ou des besoins.</p>
    <p>Au besoin, il est possible de monter un programme de formation destiné aux travailleurs pour le développement de nouvelles compétences, de manière à pouvoir réaffecter la main-d’œuvre, notamment pour assurer une meilleure transition vers le numérique.</p>
    <h4>Assurer la cybersécurité</h4>
    <p>Une bonne protection contre de potentielles cyberattaques évite des pertes financières. Les pirates informatiques convoitent les données des entreprises et emploient des techniques de plus en plus sophistiquées pour s’en saisir ou empêcher les entreprises d’y accéder.</p>
    <p>Les pirates exigent souvent le paiement d’une rançon pour redonner aux entreprises l’accès à leurs données. Le pillage de données peut provoquer la perte de confiance des fournisseurs et des consommateurs d’une entreprise. Cette perte de confiance a des répercussions négatives sur la vente et la production de marchandises.</p>
    <p>L’utilisation de données de qualité et en quantité suffisante améliore la performance des applications de l’IA en entreprise. De par leur nature commerciale (p. ex. temps de production) ou sensible (p. ex. informations personnelles), ces données requièrent un niveau de protection élevé.</p>
    <p>Il est prudent d’analyser les risques associés à la sécurité de l’environnement informatique de l’entreprise. Cette analyse conduit à la configuration de solutions pour sécuriser les systèmes. La sécurisation continue et proactive des données nécessite toutefois une mise à jour fréquente des méthodes de protection.</p>
    <h4>Préserver la responsabilité et l’imputabilité humaines</h4>
    <p>La validité d’une analyse émise par l’IA doit être étudiée avec réserve, car elle n’est pas à l’abri de biais ou d’inexactitudes, étant fonction de la qualité des données fournies au système. L’emploi de ces outils d’aide à la prise de décision ne doit pas miner l’autonomie de l’humain ou réduire sa responsabilité et son imputabilité. La décision finale doit appartenir à un professionnel en mesure de tenir compte des réalités de l’entreprise.</p>
    <p>L’IA propose des outils d’analyse qui assurent un meilleur suivi de la gestion des inventaires et de l’entretien des équipements. Cependant, la pertinence des recommandations émises par l’IA dépend fortement de la qualité des données qui ont été utilisées pour entraîner le système. Si les données d’entraînement diffèrent de celles utilisées sur le terrain, cela peut donner lieu à des suggestions pas toujours adaptées.</p>
    <p>Il est recommandé de privilégier l’emploi de données accessibles. Une plus grande transparence du programme peut contribuer à l’analyse des recommandations du système. Une meilleure compréhension de la proposition faite par l’IA facilitera l’évaluation de sa pertinence par la personne responsable de l’appliquer. Cette dernière pourra agir de manière éclairée pour prendre une décision.</p>
    <p>Il est aussi possible de définir les conditions auxquelles doit répondre une recommandation pour être jugée recevable. Une autre avenue à explorer consiste à préciser le type d’erreurs et le niveau de tolérance de ces erreurs, selon leur origine (humaine ou informatique).</p>
    <h4>Éviter la présence de biais</h4>
    <p>Pour effectuer leurs analyses, les technologies d’IA se basent sur des données fournies par des humains, lesquels ne sont pas à l’abri de biais. Au cours de leur entraînement, ces technologies traitent les données qui leur sont fournies et font des apprentissages à partir de ces informations. Si les données sont incomplètes ou ne représentent pas bien la réalité, le système peut reproduire des biais.</p>
    <p>À ce jour, il existe peu de règles relatives à l’encadrement de l’IA. C’est pourquoi il convient d’être sensible à la présence de biais dans les applications basées sur l’IA. Plus l’équipe de conception perçoit l’importance de ces enjeux, moins grands sont les risques d’obtenir un résultat biaisé. Le système d’apprentissage sera moins sensible aux préjugés, conscients ou non, s’il est enrichi de données ou de choix de design qui sont représentatifs de la réalité. L’intégration de l’IA exige aussi de tenir compte de la diversité des contextes dans lesquels elle pourrait être utilisée.</p>
    <h4>Maintenir la confiance</h4>
    <p>Les technologies basées sur l’IA peuvent inspirer une certaine méfiance, chez le personnel comme chez la clientèle. L’adoption de ces technologies doit donc être encadrée.</p>
    <h4>La clientèle</h4>
    <p>Le manque d’humanité dans les interactions avec un robot conversationnel peut déplaire à certains utilisateurs. Offrez toujours à vos clients la possibilité de communiquer avec une personne si le robot ne peut fournir les informations recherchées. Informez vos clients et vos fournisseurs des mesures prises pour assurer la protection de leurs renseignements personnels.</p>
    <h4>Le personnel</h4>
    <p>Une attitude conciliante de la direction aplanit la méfiance du personnel envers l’adoption de nouvelles technologies. Le fait d’encourager les personnes réticentes à exprimer leurs craintes présente l’avantage de pouvoir les aider à diminuer leurs appréhensions. Le partage d’information, de documentation et la proposition de formations réduiront les craintes soulevées par l’implantation de l’IA. Ces initiatives donnent aussi l’occasion d’expliquer le cadre d’utilisation de l’IA et peuvent contribuer à rendre l’ensemble de la démarche plus clair.</p>
    <p>Une plus grande transparence a le potentiel de redonner une certaine confiance aux gens qui ont des inquiétudes.</p>
    <h4>Respecter la sphère privée</h4>
    <p>L’implantation de l’IA ne doit pas s’exercer au détriment de la sphère privée des employés. Le déploiement de l’IA doit éviter la collecte et la diffusion de données en l’absence d’un consentement éclairé.</p>
    <p>Avant d’implanter un système pour surveiller le travail des employés, informez-vous du code de santé et de sécurité et des normes de la Commission des normes, de l’équité, de la santé et de la sécurité du travail (CNESST).</p>
  </div>
</template>

<script>
export default {
  name: "IndustrieManufacturiereEthics"
}
</script>

<style scoped>

</style>