<template>
  <div>
    <h1 class="content-title">{{ $t("guide") }}</h1>
    <div>
      <p>
        Cette page Web sert de référence pour l'utilisation des différentes
        interactivités disponibles dans le livre numérique. Les actions
        effectuées dans les interactivités sont énumérées dans la page
        <router-link to="/tools">Outils</router-link> et sont enregistrées sur
        votre ordinateur. Vous pouvez les effacer en utilisant l'interface du
        livre numérique ou en supprimant vos données de navigation.
      </p>

      <div class="mt-4">
        <ul>
          <li>
            <h5>Pages visitées</h5>
            <p>
              Lorsqu'un utilisateur consulte une page, celle-ci est
              automatiquement ajoutée à la liste des pages qu’il a consultées.
            </p>
          </li>
          <li>
            <h5>Favoris</h5>
            <p>
              Lorsqu'un utilisateur clique sur le bouton Favoris
              <img
                class="screenShot"
                alt="Vue logo"
                src="../assets/img/screenShotBookmarkNull.png"
              />
              situé en haut à droite de la page, la page Web sur laquelle il se
              trouve est ajoutée à sa liste de favoris. Le bouton Favoris
              ressemblera alors à
              <img
                class="screenShot"
                alt="Vue logo"
                src="../assets/img/screenShotBookmarkTrue.png"
              />
              sur la page.
            </p>
          </li>
          <li>
            <h5>Surlignage et commentaires associés</h5>
            <p>
              Lorsqu'un utilisateur sélectionne du texte dans une page, il a la
              possibilité de surligner et de commenter le texte sélectionné
              comme ceci :
              <img
                class="screenShot"
                alt="Vue logo"
                src="../assets/img/screenShotHighlightNull.png"
                height="60"
              />
              . Une fois surligné, le contenu sélectionné, la couleur du
              surlignage et le commentaire associé sont ajoutés à la liste des
              surlignages que l’utilisateur a faits. Le texte surligné
              ressemblera alors à ceci :
              <img
                class="screenShot"
                alt="Vue logo"
                src="../assets/img/screenShotHighlightTrue.png"
              />
              . Il est possible de modifier un surlignage en cliquant sur
              celui-ci.
            </p>
          </li>
          <li>
            <h5>Commentaires de page</h5>
            <p>
              Lorsqu'un utilisateur clique sur le bouton Commentaires
              <img
                class="screenShot"
                alt="Vue logo"
                src="../assets/img/screenShotCommentsNull.png"
              />
              situé en haut à droite de la page, des commentaires peuvent être
              ajoutés dans la page. Une fois enregistrés, les commentaires sont
              ajoutés à la liste des commentaires de page que l'utilisateur a
              faits. Le bouton Commentaires ressemblera alors à ceci :
              <img
                class="screenShot"
                alt="Vue logo"
                src="../assets/img/screenShotCommentTrue.png"
              />
              sur la page.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideView",
};
</script>

<style scoped>
.screenShot {
  border: black solid 1px;
}
</style>
