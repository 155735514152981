<template>
  <div
    id="offcanvasMainMenu"
    :class="offCanvasClass"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    aria-labelledby="offcanvasMainMenuLabel"
  >
    <div class="offcanvas-header">
      <h5 id="offcanvasMainMenuLabel" class="offcanvas-title">Menu</h5>
      <button
        type="button"
        :class="closeButtonClass"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="moveContent"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul>
        <li v-for="item in mainStructure" :key="item">
          <button
            :class="isSelected(item)"
            @click="closeSideMenuOnSmallDeviceAndGoToRoute(item)"
          >
            {{ $t(item) }}
          </button>
        </li>
        <li v-if="hasFullMenu">
          <button
            :class="btnMenuClass"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasContentTable"
            aria-controls="offcanvasContentTable"
          >
            Table des matières
            <span class="icon-btn-menu">
              <font-awesome-icon
                icon="chevron-right"
                style="color: rgba(var(--on-background-color), 0.87)"
              />
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div
    id="offcanvasContentTable"
    :class="offCanvasClass"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    aria-labelledby="offcanvasContentTableLabel"
  >
    <div class="offcanvas-header">
      <h5 id="offcanvasContentTableLabel" class="offcanvas-title">
        Table des matières
      </h5>
      <button
        type="button"
        :class="closeButtonClass"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="moveContent"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul>
        <li>
          <button
            :class="btnMenuClass"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMainMenu"
            aria-controls="offcanvasMainMenu"
          >
            Menu
            <span class="icon-btn-menu">
              <font-awesome-icon
                icon="chevron-left"
                style="color: rgba(var(--on-background-color), 0.87)"
              />
            </span>
          </button>
        </li>
        <li v-for="item in contentStructure" :key="item">
          <button
            :class="isSelected(item)"
            @click="closeSideMenuOnSmallDeviceAndGoToRoute(item)"
          >
            {{ $t(item) }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters, mapMutations } from "vuex";
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

library.add({ faChevronLeft, faChevronRight });
export default {
  name: "SideMenu",
  emits: ["toggleSideBar"],
  data() {
    return {
      hasMainElement: false,
      contentStructure: [],
      mainStructure: [],
      hasFullMenu: false,
      subSideBarOpened: false,
    };
  },
  computed: {
    ...mapGetters(["getSideCommentState", "getTheme"]),
    btnMenuClass() {
      if (this.getTheme === "dark") {
        return "btn shadow-none menu-button-dark";
      }
      return "btn shadow-none menu-button-light";
    },
    offCanvasClass() {
      if (this.getTheme === "dark") {
        return "offcanvas offcanvas-menu offcanvas-start offcanvas-dark";
      }
      return "offcanvas offcanvas-menu offcanvas-start offcanvas-light";
    },
    closeButtonClass() {
      if (this.getTheme === "dark") {
        return "btn-close text-reset offcanvas-btn btn-close-dark";
      }
      return "btn-close text-reset offcanvas-btn btn-close-light";
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations(["toggleSideMenuState", "toggleSideCommentState"]),
    isSelected(item) {
      if (item === this.$route.name) {
        if (this.getTheme === "dark") {
          return "btn shadow-none menu-button-dark selected-dark";
        }
        return "btn shadow-none menu-button-light selected-light";
      } else {
        if (this.getTheme === "dark") {
          return "btn shadow-none menu-button-dark";
        }
        return "btn shadow-none menu-button-light";
      }
    },
    getMenuStructure() {
      this.contentStructure = [];
      this.mainStructure = [];
      if (this.hasFullMenu) {
        this.contentStructure = [
          "agricultureHome",
          "agricultureCases",
          "agricultureBenefits",
          "agricultureImpacts",
          "agricultureEthics",
          "financeEtAssuranceHome",
          "financeEtAssuranceCases",
          "financeEtAssuranceBenefits",
          "financeEtAssuranceImpacts",
          "financeEtAssuranceEthics",
          "industrieManufacturiereHome",
          "industrieManufacturiereCases",
          "industrieManufacturiereBenefits",
          "industrieManufacturiereImpacts",
          "industrieManufacturiereEthics",
          "santeHome",
          "santeCases",
          "santeBenefits",
          "santeImpacts",
          "santeEthics",
          "venteAuDetailHome",
          "venteAuDetailCases",
          "venteAuDetailBenefits",
          "venteAuDetailImpacts",
          "venteAuDetailEthics",
        ];
        this.mainStructure = ["home", "guide", "tools"];
      } else {
        this.mainStructure = [
          "agricultureHome",
          "agricultureCases",
          "agricultureBenefits",
          "agricultureImpacts",
          "agricultureEthics",
          "financeEtAssuranceHome",
          "financeEtAssuranceCases",
          "financeEtAssuranceBenefits",
          "financeEtAssuranceImpacts",
          "financeEtAssuranceEthics",
          "industrieManufacturiereHome",
          "industrieManufacturiereCases",
          "industrieManufacturiereBenefits",
          "industrieManufacturiereImpacts",
          "industrieManufacturiereEthics",
          "santeHome",
          "santeCases",
          "santeBenefits",
          "santeImpacts",
          "santeEthics",
          "venteAuDetailHome",
          "venteAuDetailCases",
          "venteAuDetailBenefits",
          "venteAuDetailImpacts",
          "venteAuDetailEthics",
        ];
      }
    },
    closeSideMenuOnSmallDeviceAndGoToRoute(item) {
      this.$router.push({ name: item });
      if (window.matchMedia("(max-width: 500px)").matches) {
        this.toggleSideMenuState();
        if (
          document
            .getElementById("offcanvasMainMenu")
            .className.includes("show")
        ) {
          window.$("#offcanvasMainMenu").offcanvas("toggle");
        } else if (
          document
            .getElementById("offcanvasContentTable")
            .className.includes("show")
        ) {
          window.$("#offcanvasContentTable").offcanvas("toggle");
        }
      }
    },
    handleResize() {
      window.innerWidth > 768
        ? (this.hasFullMenu = false)
        : (this.hasFullMenu = true);
      this.contentStructure = [];
      this.getMenuStructure();
    },
    moveContent() {
      if (this.getSideCommentState) {
        this.toggleSideCommentState();
      }
      this.$emit("toggleSideBar");
      this.toggleSideMenuState();
    },
  },
};
</script>

<style lang="scss" scoped>
.offcanvas-header{
  background-color: rgba(var(--menu-bg-color), 0.9);
}
.offcanvas-title {
  color: rgba(var(--on-background-color), 0.9);
  padding-top: 1rem;
}
.offcanvas-body {
  padding: 1rem 0 0 0;
}
@media (max-width: 500px) {
  .offcanvas-start {
    top: 4.4rem;
    width: 500px;
  }
}
@media (min-width: 500px) {
  .offcanvas-start {
    top: 4.4rem;
    width: 250px;
  }
}
.offcanvas-light {
  border-right: 1px var(--menu-primary-color) solid;
}

.offcanvas-dark {
  border-right: 1px var(--menu-secondary-color) solid;
}

.offcanvas-btn {
  margin-top: 0.5rem !important;
}

.btn-close-light {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 0.7;
}

.btn-close-dark {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 1;
}

.menu-button-light {
  width: 100%;
  border-top: 1px solid rgba(var(--menu-primary-color), 1);
  padding: 1rem 1rem 1rem 1.5rem;
  text-align: left;
  background-color: rgba(var(--menu-bg-color), 0.75);
  color: rgba(var(--on-background-color), 0.9);
}

.menu-button-dark {
  width: 100%;
  border-top: 1px solid rgba(var(--menu-primary-color), 0.75);
  padding: 1rem 1rem 1rem 1.5rem;
  text-align: left;
  background-color: rgba(var(--menu-bg-color), 0.75);
  color: rgba(var(--on-background-color), 0.9);
}

*:focus {
  outline: none !important;
}

.menu-button-light:hover {
  background-color: rgba(var(--menu-primary-color), 0.9);
  border: 1px solid rgba(var(--menu-primary-color), 1);
}

li:last-of-type > .menu-button-light {
  border-bottom: 1px solid rgba(var(--menu-primary-color), 1);
}

@media (min-width: 768px) {
  li:nth-child(5n+0){
    button{
      padding-left: 4rem;
    }
  }

  li:nth-child(5n-1){
    button{
      padding-left: 4rem;
    }
  }

  li:nth-child(5n-2){
    button{
      padding-left: 4rem;
    }
  }

  li:nth-child(5n-3){
    button{
      padding-left: 4rem;
    }
  }
}






.menu-button-dark:hover {
  background-color: rgba(var(--menu-primary-color), 0.5);
  border: 1px solid rgba(var(--menu-secondary-color), 1);
}

li:last-of-type > .menu-button-dark {
  border-bottom: 1px solid rgba(var(--menu-primary-color), 0.75);
}

.selected-light {
  background-color: rgba(var(--menu-primary-color), 0.9);
}

.selected-dark {
  background-color: rgba(var(--menu-primary-color), 0.75);
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.icon-btn-menu {
  float: right;
}

.btn {
  border-radius: 0;
}
</style>
