<template>
  <h1 class="content-title">Les enjeux éthiques de l’intelligence artificielle en agriculture</h1>
  <div class="mb-4 mt-4">
    <p>Les avancées récentes en IA offrent de nombreuses technologies pour aider les agriculteurs à mieux composer avec les changements climatiques et la dégradation des sols. La tâche de répondre aux besoins alimentaires des populations humaines pose des défis auxquels les agriculteurs ne peuvent échapper, tels que la fluctuation des coûts (gaz et pétrole), l’inflation et les conditions climatiques. L’adoption des solutions qu’offre l’IA aide ces derniers à relever plusieurs défis mais soulève en même temps des enjeux éthiques. Voici quelques enjeux éthiques à considérer au moment d’implanter un projet en IA et les pistes de solution à explorer pour y faire face.</p>
    <h4>Conserver la propriété des données fournies par l’IA</h4>
    <p>Pour pouvoir profiter des systèmes basés sur l’IA, il faut fournir des données sur l’exploitation agricole. Les compagnies propriétaires des applications de l’IA ont différentes politiques quant à l’utilisation des données qu’elles recueillent. Certaines peuvent les partager ou les vendre à des tierces parties. D’autres offrent leurs logiciels gratuitement ou à peu de frais en échange d’un accès illimité aux données de leur clientèle. L’exportation de données vers une nouvelle application peut aussi exposer les agriculteurs à des risques.</p>
    <p>Ainsi, il est recommandé de s’informer sur les politiques d’utilisation des données avant d’investir dans une application afin de s’assurer de pouvoir exporter ses données et d’en conserver le contrôle. L’interopérabilité des données pourrait aussi être un enjeu lié à l’adoption ou au remplacement d’une technologie. Il vaut mieux faire quelques vérifications avant de se lancer.</p>
    <h4>Assurer une protection adéquate des données</h4>
    <p>Le recours à l’IA sur une exploitation agricole implique une informatisation des données et des processus. Or, personne n’est à l’abri des cyberattaques. Des pirates informatiques peuvent prendre le contrôle des données ou du système et exiger une rançon. Un niveau de sécurité informatique peu robuste accroît le risque de subir ce type d’attaques et présente des risques de pertes financières. Pour prévenir le cybercrime, il est préférable de mettre fréquemment à jour le système de sécurité informatique. Des corrections pourront ainsi être apportées pour résoudre les problèmes observés.</p>
    <h4>Préserver la transparence des données, la responsabilité et l’imputabilité</h4>
    <p>Si l’IA est un outil puissant, les résultats de ses prédictions ne sont pas toujours expliqués de manière claire. Le fait que l’IA repose sur des calculs mathématiques donne une impression de rigueur et d’exactitude. Il est cependant préférable de préserver la transparence des données pour bien comprendre la pertinence des recommandations émises par l’IA.</p>
    <p>Lorsque les recommandations de l’IA ne correspondent pas à la vision qu’a l’agriculteur de son exploitation agricole, il doit être en mesure de le constater. En privilégiant des systèmes d’IA qui expliquent de manière transparente les motifs de leurs décisions, l’agriculteur se donne la possibilité d’ajuster le tir au besoin. Il est aussi prudent de faire confiance au jugement professionnel des experts consultés (p. ex. agronomes, vétérinaires).</p>
    <p>Quels critères une recommandation de l’IA doit-elle respecter pour justifier qu’on la suive ? Il peut être souhaitable de les établir au préalable. ll est aussi possible de prédéfinir les types d’erreurs qui sont considérés comme acceptables et les marges d’erreurs qui sont jugées raisonnables selon leur origine (humaine ou informatique). En bref, un plan clair et établi en amont facilitera la gestion des recommandations qui s’avèrent discutables.</p>
    <h4>Éviter la présence d’erreurs et de biais dans les données</h4>
    <p>Pour créer un système d’IA, il faut l’entraîner avec un nombre considérable de données initiales. La plupart des systèmes actuels sont principalement entraînés avec les données fournies par de grandes exploitations agricoles occidentales. Parce qu’elles ont davantage accès aux technologies, les grandes exploitations peuvent accumuler des données. Ces données conditionnent les recommandations émises par les systèmes d’IA. Elles peuvent s’avérer plus adaptées pour de grandes exploitations agricoles. Il est recommandé de vérifier si les données utilisées pour entraîner le système sont représentatives des petites exploitations agricoles et des conditions climatiques observées dans la région où il est implanté.</p>
    <h4>Respecter l’environnement et le bien-être animal et humain</h4>
    <p>Les robots, sondes, capteurs et autres appareils employés dans l’utilisation des solutions de l’IA peuvent être dommageables pour l’environnement. Une utilisation plus intensive de la machinerie peut en effet augmenter le rejet de substances nocives dans le milieu de même que la compression du sol.</p>
    <p>Les recherches en IA dans le domaine de l’agriculture peuvent également s’intéresser aux modifications génétiques chez diverses espèces. L’introduction de ces espèces modifiées peut nuire à l’écosystème ambiant. Le milieu peut aussi être affecté lorsque de nouvelles espèces élevées ou cultivées sont introduites pour mieux répondre aux exigences technologiques. Par exemple, les variétés de plantes cultivées pourraient être remplacées dans le but de faciliter leur manipulation par les robots. Ces substitutions pourraient toutefois avoir un impact environnemental négatif.</p>
    <p>De plus, l’introduction de robots spécialisés dans les tâches liées aux animaux peut poser problème du point de vue de leur bien-être. En effet, cette nouvelle machinerie peut leur créer du stress. Des risques d’accidents (p. ex. collision entre une bête et un robot) sont aussi à envisager. Il est à noter que l’utilisation de véhicules (p. ex. tracteurs) à conduite autonome peut également représenter une menace pour la sécurité humaine si le système n’est pas au point ou mal entretenu.</p>
    <p>Pour faire face à ce genre de situation, il est prudent d’évaluer en amont les impacts potentiels liés à l’introduction des technologies de l’IA. Par exemple, il est préférable d’estimer les conséquences physiques de l’intégration de certains types de machinerie. Des modifications à l’aménagement de l’exploitation peuvent s’imposer. Une évaluation environnementale experte peut aussi être conduite afin de valider les recommandations faites par l’IA avant de les mettre en œuvre.</p>
  </div>
</template>

<script>
export default {
  name: "AgricultureEthics"
}
</script>

<style scoped>

</style>