<template>
  <h1 class="content-title"> {{ $t('santeHome') }} </h1>
  <div class="mb-4 mt-4">
    <p>L’intelligence artificielle est appelée à occuper une place de plus en plus importante dans le domaine de la santé. Plusieurs technologies existantes faisant appel à l’apprentissage automatique, aux réseaux de neurones et à la robotique contribuent déjà à améliorer les soins de santé. Cette tendance va s’accroître de façon exponentielle dans les prochaines années et susciter des enjeux d’intérêt public. L’IA peut donner un nouveau souffle à la santé.</p>
    <p>Vous êtes invité à utiliser le livre numérique pour noter vos idées ou vos réflexions, ou encore pour surligner un extrait de texte particulièrement pertinent à vos yeux.</p>
  </div>
</template>

<script>
export default {
  name: "SanteHome"
}
</script>

<style scoped>

</style>