import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import GuideView from "../views/GuideView.vue";
import ToolsView from "../views/ToolsView.vue";
// import BigData from "../views/content/BigData";
// import DataAnalysis from "../views/content/DataAnalysis";
// import DataGeneration from "../views/content/DataGeneration";
// import DeepLearning from "../views/content/DeepLearning";
// import ImageRecognition from "../views/content/ImageRecognition";
// import InternetOfThings from "../views/content/InternetOfThings";
// import MachineLearning from "../views/content/MachineLearning";
// import NaturalLanguageProcessing from "../views/content/NaturalLanguageProcessing";
// import NeuralNetwork from "../views/content/NeuralNetwork";
// import VoiceRecognition from "../views/content/VoiceRecognition";
import VenteAuDetailHome from "../views/content/VenteAuDetailHome";
import VenteAuDetailCases from "../views/content/VenteAuDetailCases";
import VenteAuDetailBenefits from "../views/content/VenteAuDetailBenefits";
import VenteAuDetailImpacts from "../views/content/VenteAuDetailImpacts";
import VenteAuDetailEthics from "../views/content/VenteAuDetailEthics";
import AgricultureHome from "../views/content/AgricultureHome";
import AgricultureCases from "../views/content/AgricultureCases";
import AgricultureBenefits from "../views/content/AgricultureBenefits";
import AgricultureImpacts from "../views/content/AgricultureImpacts";
import AgricultureEthics from "../views/content/AgricultureEthics";
import SanteHome from "../views/content/SanteHome";
import SanteCases from "../views/content/SanteCases";
import SanteBenefits from "../views/content/SanteBenefits";
import SanteImpacts from "../views/content/SanteImpacts";
import SanteEthics from "../views/content/SanteEthics";
import IndustrieManufacturiereHome from "../views/content/IndustrieManufacturiereHome";
import IndustrieManufacturiereCases from "../views/content/IndustrieManufacturiereCases";
import IndustrieManufacturiereBenefits from "../views/content/IndustrieManufacturiereBenefits";
import IndustrieManufacturiereImpacts from "../views/content/IndustrieManufacturiereImpacts";
import IndustrieManufacturiereEthics from "../views/content/IndustrieManufacturiereEthics";
import FinanceEtAssuranceHome from "../views/content/FinanceEtAssuranceHome";
import FinanceEtAssuranceCases from "../views/content/FinanceEtAssuranceCases";
import FinanceEtAssuranceBenefits from "../views/content/FinanceEtAssuranceBenefits";
import FinanceEtAssuranceImpacts from "../views/content/FinanceEtAssuranceImpacts";
import FinanceEtAssuranceEthics from "../views/content/FinanceEtAssuranceEthics";

import ParentPage from "../views/content/ParentPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/guide",
    name: "guide",
    component: GuideView,
  },
  {
    path: "/tools",
    name: "tools",
    component: ToolsView,
  },
  {
    path: "/",
    name: "parentPage",
    component: ParentPage,
    children: [
      {
        path: "agricultureHome",
        name: "agricultureHome",
        component: AgricultureHome,
      },
      {
        path: "agricultureCases",
        name: "agricultureCases",
        component: AgricultureCases,
      },
      {
        path: "agricultureBenefits",
        name: "agricultureBenefits",
        component: AgricultureBenefits,
      },
      {
        path: "agricultureImpacts",
        name: "agricultureImpacts",
        component: AgricultureImpacts,
      },
      {
        path: "agricultureEthics",
        name: "agricultureEthics",
        component: AgricultureEthics,
      },
      {
        path: "financeEtAssuranceHome",
        name: "financeEtAssuranceHome",
        component: FinanceEtAssuranceHome,
      },
      {
        path: "financeEtAssuranceCases",
        name: "financeEtAssuranceCases",
        component: FinanceEtAssuranceCases,
      },
      {
        path: "financeEtAssuranceBenefits",
        name: "financeEtAssuranceBenefits",
        component: FinanceEtAssuranceBenefits,
      },
      {
        path: "financeEtAssuranceImpacts",
        name: "financeEtAssuranceImpacts",
        component: FinanceEtAssuranceImpacts,
      },
      {
        path: "financeEtAssuranceEthics",
        name: "financeEtAssuranceEthics",
        component: FinanceEtAssuranceEthics,
      },
      {
        path: "industrieManufacturiereHome",
        name: "industrieManufacturiereHome",
        component: IndustrieManufacturiereHome,
      },
      {
        path: "industrieManufacturiereCases",
        name: "industrieManufacturiereCases",
        component: IndustrieManufacturiereCases,
      },
      {
        path: "industrieManufacturiereBenefits",
        name: "industrieManufacturiereBenefits",
        component: IndustrieManufacturiereBenefits,
      },
      {
        path: "industrieManufacturiereImpacts",
        name: "industrieManufacturiereImpacts",
        component: IndustrieManufacturiereImpacts,
      },
      {
        path: "industrieManufacturiereEthics",
        name: "industrieManufacturiereEthics",
        component: IndustrieManufacturiereEthics,
      },
      {
        path: "santeHome",
        name: "santeHome",
        component: SanteHome,
      },
      {
        path: "santeCases",
        name: "santeCases",
        component: SanteCases,
      },
      {
        path: "santeBenefits",
        name: "santeBenefits",
        component: SanteBenefits,
      },
      {
        path: "santeImpacts",
        name: "santeImpacts",
        component: SanteImpacts,
      },
      {
        path: "santeEthics",
        name: "santeEthics",
        component: SanteEthics,
      },
      {
        path: "venteAuDetailHome",
        name: "venteAuDetailHome",
        component: VenteAuDetailHome,
      },
      {
        path: "venteAuDetailCases",
        name: "venteAuDetailCases",
        component: VenteAuDetailCases,
      },
      {
        path: "venteAuDetailBenefits",
        name: "venteAuDetailBenefits",
        component: VenteAuDetailBenefits,
      },
      {
        path: "venteAuDetailImpacts",
        name: "venteAuDetailImpacts",
        component: VenteAuDetailImpacts,
      },
      {
        path: "venteAuDetailEthics",
        name: "venteAuDetailEthics",
        component: VenteAuDetailEthics,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  localStorage.setItem("route", to.path);
});

export default router;
