<template>
  <h1 class="content-title">Les cas d’usage de l’intelligence artificielle en santé</h1>
  <div class="mb-4 mt-4">
    <p>L’IA ne remplacera jamais les professionnels de la santé, mais elle peut modifier leur emploi du temps, les libérer de tâches administratives répétitives (analyse, classification et gestion de données administratives) et faciliter la prise de décisions. La capacité des systèmes de l’IA dépasse celle du cerveau humain. Ces systèmes sont en mesure de traiter une multitude de données et en établissant des liens complexes entre les données, ils peuvent émettre des prédictions. Les recherches se poursuivent et sont appelées à multiplier les cas d’usage aujourd’hui observés pour contribuer à la prescription de traitements et de médicaments, au diagnostic, au dépistage, à la prévention, à la gestion des soins et la formation.</p>
    <h3>Le diagnostic, le dépistage et la prévention des maladies</h3>
    <p>D’innombrables quantités de données sur diverses maladies sont continuellement récoltées dans le secteur de la santé (hôpitaux, RAMQ, études cliniques, etc.)&nbsp;: les symptômes, les facteurs de risque, le nombre de cas, les traitements, etc. L’intelligence artificielle est nécessaire pour faire «&nbsp;parler&nbsp;» ces données en vue de réussir à dépister de façon précoce les maladies et, pourquoi pas, à les prévenir.</p>
    <p>Il y a également de plus en plus d’applications numériques d’IA conçues pour aider le corps médical à poser rapidement des diagnostics plus précis. L’IA peut traiter des milliers de données relatives à un patient et identifier des tendances que le cerveau humain n’est pas en mesure de détecter. Grâce à l’apprentissage automatique, l’IA peut aussi prédire l’évolution d’une maladie.</p>
    <h4>La détection des septicémies</h4>
    <p>L’IA est utilisée pour concevoir un système d’alerte précoce de la septicémie, un empoisonnement du sang qui peut survenir à la suite d’une infection. Des chercheurs recueillent actuellement 44 000 jours de données dans les dossiers médicaux électroniques de près de 8000 patients pour créer un modèle informatique. À terme, il serait possible de détecter une septicémie plusieurs heures avant qu’elle ne se produise, ce qui réduirait le risque de mortalité de 20&nbsp;%.</p>
    <h4>Le traçage des variants de la COVID-19</h4>
    <p>On utilise l’intelligence artificielle et la modélisation pour identifier et surveiller en temps réel l’apparition et l’évolution de variants de la COVID-19. Un modèle développé à cette fin, nommé EWS, peut également prédire le potentiel infectieux des variants.</p>
    <p>Au cours des tests réalisés, EWS a démontré sa capacité à identifier plus de 90&nbsp;% des variants désignés comme préoccupants par l’Organisation mondiale de la santé, et ce, deux mois avant leur signalement dans la communauté.</p>
    <h4>L’apparition de nouveaux virus</h4>
    <p>La pandémie de COVID-19 a stimulé le développement de modèles d’apprentissage automatique et de modèles statistiques capables de prédire l’apparition de nouveaux virus, c’est-à-dire d’identifier les espèces animales qui représentent des hôtes potentiels de nouveaux virus pouvant être transmis à l’humain.</p>
    <p>Comme l’échantillonnage d’animaux sur le terrain pour vérifier s’ils sont des réservoirs de virus s’avère coûteux, des chercheurs veulent utiliser les algorithmes et l’IA afin de déterminer les endroits et les espèces à analyser de façon prioritaire. Un projet teste présentement l’association hôte-virus chez les chauves-souris du monde entier.</p>
    <h4>La prédiction des comportements suicidaires chez les étudiants</h4>
    <p>Un algorithme a été développé pour cibler les principaux facteurs prédictifs du risque suicidaire. Une méthode d’apprentissage automatique a ensuite «&nbsp;fait le ménage&nbsp;» parmi 70 facteurs potentiels pour conclure que 4 d’entre eux permettent de dépister environ 80&nbsp;% des comportements suicidaires&nbsp;: les pensées suicidaires, l’anxiété, les syndromes dépressifs et l’estime de soi. Ce projet ouvre la porte au dépistage et à la prise en charge plus rapides et efficaces des étudiants jugés à risque de suicide. La population étudiante présente un risque de suicide particulièrement élevé à cause de l’anxiété de performance, du passage à la vie adulte et de la charge de travail qui augmente sans cesse. La pandémie est venue exacerber ce risque en raison de l’isolement qu’elle a provoqué.</p>
    <h4>La détection d’une psychose</h4>
    <p>Le projet PsyCARE vise à améliorer la détection et l’intervention précoces pour la psychose, qui touche 3&nbsp;% de la population au moins une fois dans sa vie. En effet, si la première psychose n’est pas traitée, elle peut évoluer vers un trouble psychologique persistant.</p>
    <p>Les chercheurs basent leurs systèmes sur des biomarqueurs qui serviront au diagnostic et à la détection du stade de la maladie. L’IA analysera toutes les données (biomarqueurs, images médicales, symptômes) et identifiera les signes précurseurs de la psychose. Une fois terminé, le projet prendra aussi la forme d’une application mobile pour que les professionnels de la santé et leurs patients puissent échanger et personnaliser les thérapies.</p>
    <h4>L’anticipation des crises d’épilepsie</h4>
    <p>Il est possible de prédire les crises d’épilepsie en surveillant l’activité électrique du cerveau grâce à des électrodes placées sur la tête ou dans la boîte crânienne. Plus récemment, des chercheurs travaillent sur un concept moins invasif : des capteurs miniatures installés dans des vêtements. Ceux-ci enregistrent continuellement le rythme cardiaque, la respiration et les mouvements du corps, qui changent à l’approche d’une crise épileptique. Les données sont ensuite analysées par des algorithmes et des techniques d’intelligence artificielle pour annoncer la crise avant qu’elle ne se produise. Les gens pourraient ainsi avertir un proche ou leur équipe médicale et auraient le temps de s’installer dans un endroit sûr pour éviter les chutes.</p>
    <p>L’épilepsie touche près de 1&nbsp;% de la population mondiale, ce qui en fait l’une des maladies neurologiques les plus fréquentes. Malgré les avancées dans les traitements, un tiers des patients continuent d’avoir des crises invalidantes et potentiellement mortelles.</p>
    <h4>Le suivi des grossesses</h4>
    <p>Le logiciel SUOG vise à améliorer la qualité des examens échographiques de grossesse. Ces examens présentent une complexité croissante et il manque d’experts pour en interpréter les résultats. Le système assiste les professionnels de la santé en présence de caractéristiques inhabituelles d’échographie. Les images échographiques archivées dans une banque de données permettent au système de les comparer aux images présentant des anomalies pour proposer des avis intelligents qui sont validés par des experts. Ces conclusions validées aident la prise de décisions du médecin afin qu’il pose le bon geste.</p>
    <h4>La détection de l’ostéoporose</h4>
    <p>L’ostéoporose est une maladie silencieuse des os qui se déclare souvent quand survient une fracture. Le système Epifractal permettra la détection de l’ostéoporose en identifiant les patients à risque élevé de fractures liées à cette maladie, dans le but de les suivre de façon personnalisée. Ceci est rendu possible grâce à l’analyse de milliers de comptes rendus médicaux et de données cliniques par des algorithmes de détection automatique.</p>
    <h4>La prévention et le traitement des maladies rénales</h4>
    <p>Une technologie toujours en développement pourrait aider les personnes atteintes d’une maladie rénale chronique à mieux gérer leur maladie de façon à ce qu’elle ne progresse pas vers une insuffisance rénale.</p>
    <p>À l’aide d’une montre connectée (p. ex. Fitbit) et d’un tensiomètre compatible Bluetooth, une personne enregistre de façon continue ses principaux signes vitaux (pression artérielle, fréquence cardiaque) et ses activités (sommeil, exercice). Ensuite, ces données sont traitées par une application conviviale installée dans le téléphone mobile de la personne. Cette application propose notamment des conseils personnalisés relatifs à l’alimentation et à l’activité physique, et fait des rappels de la médication à prendre.</p>
    <p>Les données sont également partagées avec un professionnel de la santé (p. ex. un médecin de famille) qui peut les visualiser dans un portail Web et envoyer au besoin des messages à la personne par le biais de l’application.</p>
    <p>Des recherches en IA ont aussi pour but d’arriver à mieux analyser les données des patients afin de trouver des corrélations qui pourraient permettre de mieux comprendre la maladie et ainsi de faire de meilleures recommandations aux patients.</p>
    <h4>La prédiction d’une crise cardiaque</h4>
    <p>L’arythmie cardiaque est une anomalie de la fréquence cardiaque qui peut, dans certains cas, provoquer une crise cardiaque. Des chercheurs ont conçu un algorithme intelligent qui évalue les cicatrices du tissu cardiaque afin d’identifier les patients les plus à risque d’avoir une crise cardiaque au cours des 10 prochaines années. Par l’apprentissage profond et les réseaux de neurones, l’IA peut analyser les images de cœurs malades, les antécédents des patients et leurs cicatrices cardiaques.</p>
    <h4>Faire parler les images médicales</h4>
    <p>Plusieurs chercheurs tentent d’automatiser l’interprétation de radiographies ou d’images médicales tirées de l’imagerie par résonance magnétique. Une société de logiciels d’IA spécialisés en neuro-oncologie propose une technologie capable d’extraire des anomalies dans les images par résonance magnétique afin de détecter les lésions du cerveau, qui échappent souvent aux professionnels de la santé. En cancérologie, l’automatisation de l’interprétation des radiographies (abdominales ou pelviennes) et des mammographies permet de poser des diagnostics plus précis pour mieux établir les traitements de radiothérapie.</p>
    <h4>Le diagnostic de l’arthrose du genou</h4>
    <p>Une équipe de chercheurs québécois a recours à l’intelligence artificielle pour concevoir des outils d’évaluation de l’arthrose du genou, une maladie qui affecte 13&nbsp;% de la population canadienne selon l’Agence de la santé publique du Canada. L’outil de mesure aidera aussi les cliniciens à évaluer les risques de progression de la maladie. Les chercheurs utilisent des outils basés sur des méthodes en intelligence artificielle pour conduire leurs travaux. Ils classifient et analysent des données biomédicales pour mettre au point un système d’aide à la prise de décision clinique.</p>
    <h4>L’analyse des yeux pour découvrir des maladies</h4>
    <p>Grâce à l’analyse des yeux par l’IA, il est possible de dépister des commotions cérébrales, des maladies neurologiques ou ophtalmologiques ou encore des problèmes cardiaques.</p>
    <p>Par exemple, un algorithme d’apprentissage profond parvient à dépister avec 98,5&nbsp;% de précision la rétinopathie diabétique à partir d’une banque d’images de rétines de 225&nbsp;000 patients dans 16 pays. Cette pathologie se caractérise par la détérioration des petits vaisseaux sanguins dans la rétine, provoquant une altération du champ visuel chez les personnes vivant avec le diabète.</p>
    <p>D’autres chercheurs utilisent des balayages (scans) rétiniens pour prédire les infarctus du myocarde (crises cardiaques) 12 mois avant qu’ils ne se produisent. Le balayage rétinien est un examen très rapide qui utilise une source lumineuse de faible intensité et un capteur pour numériser les vaisseaux sanguins à l’arrière de la rétine. Comme certaines caractéristiques de ces vaisseaux (densité, dilatation, etc.) sont associées à la fonction cardiaque, les chercheurs ont examiné plus de 5000 balayages rétiniens pour cibler ceux qui correspondaient aux patients qui avaient eu une crise cardiaque par la suite. Ils ont ensuite entraîné un algorithme à reconnaître les anomalies à l’aide de la technique d’apprentissage profond.</p>
    <h4>Le diagnostic du cancer de la peau à partir d’une photo</h4>
    <p>L’application mobile SkinIO est capable de détecter en quelques minutes et à distance un possible cancer de la peau sur des photos de lésions cutanées téléversées. Les prédictions s’avèrent équivalentes à celles avancées par les dermatologues. L’outil fonctionne avec un algorithme d’apprentissage profond qui a été entraîné à détecter les anomalies cutanées avec quelque 130&nbsp;000 images de cancer de la peau. Un dermatologue pourra, selon les prédictions de l’application, demander de voir rapidement un patient jugé à risque.</p>
    <h3>Les traitements et les outils d’aide à la décision clinique</h3>
    <p>En analysant différents types de données, comme les images médicales et les résultats de tests cliniques, les outils de l’IA peuvent aider les médecins à prendre les meilleures décisions thérapeutiques possibles.</p>
    <h4>Le traitement du cancer</h4>
    <p>Les systèmes intelligents combinant analyse de données et apprentissage automatique recommandent les meilleurs traitements contre le cancer en s’appuyant sur les données des dossiers médicaux et les données extraites de la littérature et de lignes directrices médicales. Cette technologie peut notamment identifier des similarités entre des cas cliniques en fouillant les données médicales grâce aux réseaux de neurones.</p>
    <p>Par ailleurs, l’IA peut planifier en quelques minutes des traitements de radiothérapie, une planification qui exige normalement des heures de travail. Cette planification est ensuite revue et ajustée au besoin par le médecin.</p>
    <h4>Le traitement des maladies mentales</h4>
    <p>Grâce à l’apprentissage automatique, il a été possible de comparer les conclusions de traitements de maladies mentales recommandés par cinq professionnels du comportement avec celles obtenues par leur modèle informatique. Le modèle a produit moins d’erreurs de décision thérapeutique que les professionnels, qui ne s’entendaient sur les traitements qu’environ 75&nbsp;% du temps. En effet, il est possible que les membres d’un corps médical ne s’entendent pas toujours sur l’efficacité des interventions comportementales, ce qui peut conduire à des traitements inadéquats.</p>
    <h4>Le traitement de la dépression</h4>
    <p>Dans le but d’améliorer les soins en santé mentale, un outil d’aide à la prise de décision clinique est en développement pour aider les psychiatres à choisir le traitement de la dépression le plus adapté aux caractéristiques individuelles de leurs patients. Grâce à une analyse des données personnelles, la plateforme intelligente peut prédire la réaction du patient à chaque type de traitement et également suivre la personne tout au long de ses traitements. Les informations sur sa santé comportementale sont traitées par un algorithme clinique, en fonction de lignes directrices médicales. Grâce à cette analyse, le médecin peut déterminer le stade de la maladie du patient, suivre son état au fil du temps et prévoir la suite du traitement, comme l’ajout d’une psychothérapie ou une adaptation du traitement.</p>
    <p>La pandémie de COVID-19 a fait augmenter de façon notable les niveaux de dépression et d’anxiété en Amérique du Nord notamment. Le suivi de la santé mentale comporte plusieurs défis, dont celui de trouver le bon traitement. En effet, près de 70&nbsp;% des gens atteints d’une maladie mentale ne ressentent aucun soulagement après leur premier traitement, et 30&nbsp;% ne connaîtront toujours pas d’amélioration de leur condition à l’issue du quatrième.</p>
    <h4>Les traitements chirurgicaux</h4>
    <p>Les assistants chirurgicaux robotiques peuvent tenir des instruments comme des pinces, des scalpels, des ciseaux, et ils sont programmés pour les manipuler avec précision dans les blocs opératoires. On s’en sert pour pratiquer des chirurgies cardiaques, orthopédiques, vasculaires, urologiques ou touchant la colonne vertébrale. Les nanorobots, des machines miniatures et intelligentes, sont en mesure d’atteindre des régions inaccessibles par d’autres moyens. Les prochains modèles d’assistants chirurgicaux robotiques seront équipés d’une vision artificielle qui permettra aux chirurgiens de mieux visualiser les tissus internes. On s’attend à ce que les modèles d’assistants à venir puissent fournir une rétroaction immédiate pendant l’opération.</p>
    <h3>La découverte de nouveaux médicaments et traitements</h3>
    <p>L’intelligence artificielle joue également un rôle dans la découverte de médicaments. L’apprentissage automatique, par exemple, permet d’analyser rapidement des données afin de sélectionner les meilleures molécules pour la fabrication d’un médicament en fonction de critères comme leurs propriétés, les coûts et la disponibilité.</p>
    <p>Les informations recueillies et analysées par des systèmes intelligents servent à créer des modèles 3D qui sont ensuite utilisés pour concevoir et tester l’efficacité d’une molécule de façon virtuelle, avant de passer à de véritables tests cliniques. Certaines technologies peuvent même analyser le profil des patients afin de déterminer quels groupes répondront le mieux aux médicaments créés.</p>
    <p>Par exemple, en exploitant divers algorithmes, il est possible de trouver la population souhaitée pour tester une médication et montrer son efficacité, ainsi que de créer des sous-populations de patients en fonction de leur susceptibilité à bien réagir au médicament ou à présenter des complications.</p>
    <p>L’IA peut d’ailleurs assurer un suivi précis des effets indésirables d’un nouveau médicament en analysant une multitude de données comme les visites chez le médecin et les rapports médicaux.</p>
    <h4>La conception de couronnes parfaites</h4>
    <p>Un outil informatique intelligent (algorithmes d’apprentissage automatique et réseaux de neurones) intégré à un processus de design peut concevoir des couronnes dentaires qui s’arriment parfaitement avec les dents du patient. Cette technologie s’apparente aux hypertrucages (deepfakes) pour reproduire les creux, les pics, les bosses et autres caractéristiques uniques des dents.</p>
    <h4>La lutte à la résistance antibiotique</h4>
    <p>Une technologie en développement veut combiner l’IA et le criblage à haut débit par microscope – une technique qui permet d’analyser rapidement un vaste éventail de molécules et de mutations génétiques – afin de trouver de nouveaux antibiotiques que les bactéries n’auraient jamais côtoyés. L’IA se chargera d’analyser la masse de données obtenues par criblage et de prédire quelles molécules offrent le plus fort potentiel d’activité antibiotique, pour ensuite les synthétiser et les tester cliniquement.</p>
    <h4>L’utilisation de la bonne molécule thérapeutique</h4>
    <p>Il est possible d’exploiter l’intelligence artificielle pour concevoir ou optimiser plus rapidement des molécules thérapeutiques. Des modèles informatiques intelligents peuvent prédire quelles sont les molécules d’intérêt qui devraient être testées en laboratoire, faisant ainsi gagner un temps précieux aux chercheurs qui passent actuellement un nombre incalculable d’heures à synthétiser et à tester des dizaines de molécules dans l’espoir de trouver de bonnes candidates. Ces modèles peuvent aussi prédire la toxicité des molécules, évitant de devoir rejeter un médicament à la toute fin du processus.</p>
    <h3>La gestion des soins dans les hôpitaux</h3>
    <p>Plusieurs applications de l’IA sont développées pour appuyer la gestion administrative des dossiers médicaux et des stocks ou pour prévoir la fréquentation des urgences et équilibrer les charges de travail.</p>
    <h4>La gestion des stocks</h4>
    <p>L’application DemandAMP+ propose une solution unique pour mieux gérer la chaîne d’approvisionnement des produits de soins de santé axée sur la demande des patients. Cette technologie alimentée par une plateforme d’intelligence artificielle permet de prévoir avec exactitude les achats de fournitures médicales et de médicaments, minimisant ainsi les pertes ou les ruptures de stock.</p>
    <h4>La prédiction de l’affluence dans les hôpitaux</h4>
    <p>Le logiciel français CALYPS Saniia exploite l’IA (algorithmes et réseaux de neurones artificiels) pour désengorger les urgences dans un hôpital de France. Cette technologie en développement anticipe le nombre d’arrivées jusqu’à cinq jours d’avance avec une précision de 90 %. Elle peut aussi prévoir la répartition des lits.</p>
    <p>L’entreprise Maisha Labs a par ailleurs développé une plateforme intelligente pour gérer le flot entrant et sortant de patients à l’Hôpital général juif à Montréal, le tout avec une précision de 98 %. Le logiciel analyse en temps réel l’achalandage mais aussi la localisation des patients atteints de COVID-19 pour mieux gérer les risques d’infection.</p>
    <p>Logibec Réorientation est une solution innovante pour réduire le débordement des salles d’urgence et le temps d’attente ambulatoire. Il facilite le travail des professionnels du triage en identifiant les cas non urgents et en les redirigeant rapidement vers la clinique partenaire sélectionnée. Il a pour résultat des salles d’attente moins achalandées, un meilleur accès aux urgences et des patients plus satisfaits.</p>
    <h4>L’allocation des ressources</h4>
    <p>Plusieurs applications exploitent l’IA pour planifier les horaires de travail des médecins et du personnel infirmier. La plateforme Petal de même que la solution Logibec Gestion des horaires proposent des outils capables de considérer un grand nombre de contraintes et d’analyser les planifications et la répartition des tâches pour proposer des horaires adaptés à chacun des services.</p>
    <p>La solution Logibec Strom équilibre les charges de travail lors de la planification des horaires. L’entreprise utilise des extensions d’intelligence artificielle pour aider les gestionnaires à prendre des décisions stratégiques selon les besoins en personnel. Les algorithmes d’optimisation et de prédiction permettent notamment de réduire les coûts liés à l’absentéisme ou à l’augmentation de l’occupation d’un hôpital.</p>
    <h4>L’accessibilité des soins</h4>
    <p>La plateforme Petal propose un système de prises de rendez-vous en mesure de considérer les préférences des patients. L’IA permet de traiter une multitude d’informations sur la demande du patient, sur les données démographiques et sur sa relation avec le médecin. Le système propose un rendez-vous adapté à la situation de chaque patient. En télémédecine, d’autres technologies de l’IA permettent de réunir toutes les données sur l’état de santé du patient avant de le diriger vers le bon professionnel. Certaines applications sont aussi testées pour accélérer le triage des patients aux urgences.</p>
    <h4>La prévision de la capacité clinique</h4>
    <p>Des applications de l’IA sont en mesure de comparer l’offre de soins avec les demandes de patients selon les saisons, comme peut le faire la plateforme Petal. Ces informations permettent aux gestionnaires d’équilibrer l’offre de soins en évitant de déployer inutilement des efforts ou de provoquer une pénurie.</p>
    <h3>La personnalisation des soins de santé</h3>
    <p>L’IA est de plus en plus utilisée pour recommander des soins personnalisés grâce à l’analyse intelligente des données du patient.</p>
    <p>L’IA peut tenir compte de plusieurs facteurs comme les habitudes de vie d’un individu, son environnement, ses gènes et la présence de biomarqueurs dans son corps, ses antécédents médicaux, son alimentation, et son état physique et psychologique. Ces indicateurs permettent aux médecins de proposer des traitements personnalisés qui provoquent moins d’effets secondaires incommodants.</p>
    <p>Plusieurs applications de médecine spécialisée aident à mieux comprendre l’interaction d’un médicament avec les gènes d’un individu. Ces applications sont aussi utilisées pour comprendre le génome d’une tumeur, adapter le traitement d’un individu et évaluer ses risques de récidive.</p>
    <p>L’IA permet d’estimer les risques qu’a un individu de contracter une maladie, comme certains types de cancers, au cours de sa vie en se basant sur des centaines de facteurs de risque, en plus de considérer ses habitudes de vie et son âge. L’ajout de variables assure la création de bases de données plus complètes qui produisent de meilleures estimations. Les résultats de ces modèles peuvent varier selon la quantité et la qualité des données utilisées.</p>
    <p>Grâce aux méthodes d’apprentissage automatique, de reconnaissance d’images et de l’Internet des objets, il est possible de capter à distance les signes vitaux d’une personne en convalescence à son domicile et de les transmettre à l’équipe soignante, qui recommande ensuite des soins ou de l’accompagnement personnalisés.</p>
    <h3>La télémédecine</h3>
    <p>Depuis plusieurs années, l’accès à un médecin est un enjeu de taille au Québec, particulièrement pour les gens qui demeurent en région éloignée. L’IA peut favoriser l’accès à distance à un médecin spécialiste grâce à des consultations en télémédecine et à l’accès aux données du patient à distance.</p>
    <h4>Un meilleur suivi des soins à domicile</h4>
    <p>Une entreprise montréalaise a développé un système d’IA pour mieux organiser et administrer les soins à domicile, un milieu dans lequel on observe un taux de roulement de personnel important. L’algorithme peut prendre en compte les contraintes d’une organisation, par exemple en réduisant le temps de voyagement du personnel d’une visite à l’autre, en maximisant le temps de travail du personnel, en répondant à toutes les demandes de visites, en garantissant que les soins seront offerts par la même personne d’une semaine à l’autre. Les algorithmes sont aussi en mesure d’émettre des alertes pour signaler les risques auxquels s’expose un patient en assurant une surveillance à distance. Le système peut prendre les signes vitaux, reconnaître les mouvements et détecter les chutes.</p>
    <h4>Le recours à des vêtements qui parlent</h4>
    <p>Une autre façon de suivre des patients à distance fait intervenir des vêtements intelligents. Une compagnie de Montréal développe des vêtements biométriques qui enregistrent les signes vitaux, comme les fréquences cardiaque et respiratoire. Une fois saisies dans une plateforme de santé connectée, ces données peuvent être utilisées pour la recherche ou comme outil de communication pour le personnel soignant. Ces vêtements intelligents ont des utilités dans plusieurs sphères de la santé telles que la cardiologie, la pneumologie, la neurologie, la psychiatrie ou la pédiatrie, ou encore dans le domaine du sport de haut niveau.</p>
    <h4>La formation continue</h4>
    <p>Depuis quelque temps, le milieu médical utilise la réalité virtuelle et la réalité augmentée pour former les futurs professionnels de la santé ou améliorer les compétences des équipes en place. Ces technologies permettent de simuler des environnements cliniques afin de placer le personnel médical dans des situations semblables à la réalité. Combinées à l’IA, elles servent de tuteur ou de professeur médical en fournissant une rétroaction concrète en temps réel.</p>
    <p>Des études scientifiques révèlent que les simulateurs de réalité virtuelle s’appuyant sur l’apprentissage automatique peuvent, entre autres, évaluer avec précision les compétences des neurochirurgiens pendant les séances de chirurgie virtuelle et les aider à parfaire leur expertise avant d’entrer en salle d’opération.</p>
  </div>
</template>

<script>
export default {
  name: "SanteCases"
}
</script>

<style scoped>

</style>