<template>
  <div>
    <router-view/>
    <nav class="nav-container">
      <button v-if="prev" type="button" :class="btnNavPrevClass" @click="goTo($event, prev)">
        <font-awesome-icon icon="arrow-left" style="color: var(--on-background-color);"/>
        <span v-if="hasText" class="previousText">{{ $t("previous") }}</span>
      </button>
      <button v-else-if="previousChapter" type="button" :class="btnNavPrevClass" @click="goTo($event, previousChapter)">
        <font-awesome-icon icon="arrow-left" style="color: var(--on-background-color);"/>
        <span v-if="hasText" class="previousText">{{ $t("previousChapter") }}</span>
      </button>
      <button v-if="next" type="button" :class="btnNavNextClass" @click="goTo($event, next)">
        <span v-if="hasText" class="nextText">{{ $t("next") }}</span>
        <font-awesome-icon icon="arrow-right" style="color: var(--on-background-color);"/>
      </button>
      <button v-else-if="nextChapter" type="button" :class="btnNavNextClass" @click="goTo($event, nextChapter)">
        <span v-if="hasText" class="nextText">{{ $t("nextChapter") }}</span>
        <font-awesome-icon icon="arrow-right" style="color: var(--on-background-color);"/>
      </button>
    </nav>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import {mapGetters} from "vuex";

library.add({ faArrowRight, faArrowLeft })

export default {
  name: "ParentPage",
  data() {
    return {
      hasText: true,
    }
  },
  computed: {
    ...mapGetters(['getTheme']),
    btnNavPrevClass() {
      if(this.getTheme === 'dark') {
        return "previous btn btn-dark mt-5"
      }
      return 'previous btn btn-light mt-5'
    },
    btnNavNextClass() {
      if(this.getTheme === 'dark') {
        return 'next btn btn-dark mt-5'
      }
      return 'next btn btn-light mt-5'
    },
    routes() {
      return this.$router.options.routes.find(r => r.name.includes('parentPage') && this.$route.path.includes(r.path)).children;
    },
    routeIndex() {
      return this.routes.findIndex(r => r.name === this.$route.name);
    },
    prev() {
      const route = this.routes[this.routeIndex - 1];
      return route && { name: route.name };
    },
    next() {
      const route = this.routes[this.routeIndex + 1];
      return route && { name: route.name };
    },
    nextChapter() {
      for(let i = 0; i < this.$router.options.routes.length; i++) {
        if(i !== this.$router.options.routes.length && this.$router.options.routes[i].name.includes('ParentPage') && this.$route.path.includes(this.$router.options.routes[i].path)) {
          if (this.$router.options.routes[i + 1] && this.$router.options.routes[i + 1].children) {
            return this.$router.options.routes[i + 1] && {name: this.$router.options.routes[i + 1].children[0].name}
          }
        }
      }
      return null;
    },
    previousChapter() {
      for(let i = 0; i < this.$router.options.routes.length; i++) {
        if(i !== 0 && this.$router.options.routes[i].name.includes('ParentPage') && this.$route.path.includes(this.$router.options.routes[i].path)) {
          if (this.$router.options.routes[i - 1] && this.$router.options.routes[i - 1].children) {
            return this.$router.options.routes[i-1] && {name: this.$router.options.routes[i-1].children[0].name}
          }
        }
      }
      return null;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    goTo(e, route) {
      e.currentTarget.blur();
      this.$router.push(route)
    },
    handleResize() {
      this.hasText = window.innerWidth > 400;
    },
  },
}
</script>

<style scoped>

.btn-light {
  background-color: rgba(var(--nav-btn-bg-color), 0.20);
  color: var(--nav-btn-text-color);
  border: 1px solid rgba(var(--nav-btn-bg-color), 0.5);
  position: absolute;
}

.btn-light:hover {
  background-color: rgba(var(--nav-btn-bg-color), 0.55);
  border: 1px solid rgba(var(--nav-btn-bg-color), 0.5);
}

.btn-light:focus {
  background-color: rgba(var(--nav-btn-bg-color), 0.7);
  border: 1px solid rgba(var(--nav-btn-bg-color), 0.5);
  outline: none;
  box-shadow: none;
}

.btn-dark {
  background-color: rgba(var(--nav-btn-bg-color), 0.20);
  color: var(--nav-btn-text-color);
  border: 1px solid rgba(var(--nav-btn-bg-color), 1);
  position: absolute;
}

.btn-dark:hover {
  background-color: rgba(var(--nav-btn-bg-color), 0.80);
  border: 1px solid rgba(var(--nav-btn-bg-color), 5);
  color: #FFFFFF;
}

.btn-dark:focus {
  background-color: rgba(var(--nav-btn-bg-color), 0.90);
  border: 1px solid rgba(var(--nav-btn-bg-color), 5);
  color: #FFFFFF;
  outline: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .btn {
    font-size: 1.25rem;
    padding: 1rem;
  }
}

@media (min-width: 768px) {
  .btn {
    font-size: 1.25rem;
    width: 15rem;
    padding: 1rem;
  }
}

.previous {
  bottom: 0;
  left: 0;
}

.next {
  bottom: 0;
  right: 0;
}

.previousText {
  margin-left: 0.75rem;
}

.nextText {
  margin-right: 0.75rem;
}

.nav-container {
  position: relative;
  height: 5rem;
  margin-bottom: 2rem;
}


</style>