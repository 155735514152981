<template>
  <div class="accordion-item">
    <h2 id="headingSideComments" class="accordion-header">
      <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSideComments" aria-expanded="false" aria-controls="collapseSideComments" @click="closePickerIfOpen()">
        {{ $t("sideCommentList") }}
      </button>
    </h2>
    <div id="collapseSideComments" class="accordion-collapse collapse" aria-labelledby="headingSideComments" data-bs-parent="#accordionTools">
      <div :class="accordionBodyClass">
        <table v-if="commentList.length > 0" class="table table-striped align-middle" aria-label="SideComments">
          <thead>
          <tr>
            <th scope="col">{{ $t("page") }}</th>
            <th scope="col">{{ $t("content") }}</th>
            <th scope="col"></th>
            <th v-if="isCommentPanelOpened" scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(comment, index) in commentList" :key="comment">
            <td><router-link :to="{name: comment.pageName}">{{ $t(comment.pageName) }}</router-link></td>
            <td>{{ stripContentIfTooLong(comment.content) }}</td>
            <td>
              <button class="btn icon-btn" @click="openCommentPanelForIndex(index)"><font-awesome-icon icon="comment"/></button>
              <button class="btn icon-btn" @click="deleteComment(comment)"><font-awesome-icon icon="times" style="color: var(--error-color);"/></button>
            </td>
            <td v-if="isCommentPanelOpened && openedIndex === index">
              <textarea v-model="tempComment" class="comment-area" placeholder="Entrez vos commentaires liés à cette page"></textarea>
              <div class="d-flex justify-content-center">
                <button class="btn btn-success me-2 btn-action" @click="update()">Sauvegarder</button>
              </div>
            </td>
            <td v-else :style="isCommentPanelOpened ? '' : 'display: none;'"></td>
          </tr>
          </tbody>
        </table>
        <p v-else>Vous n'avez pas encore enregistré de commentaires liés à une page.</p>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapMutations} from "vuex";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons'

library.add({faTimes, faLink})

export default {
  name: "CommentList",
  data() {
    return {
      isCommentPanelOpened: false,
      openedIndex: null,
      tempComment: ''
    }
  },
  computed: {
    ...mapGetters([
      'getSideCommentList', 'getTheme'
    ]),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
    commentList() {
      return this.getSideCommentList;
    },
    tableClass() {
      let theme = localStorage.getItem("theme");
      if(theme === 'dark') {
        return 'table table-dark table-striped align-middle';
      }
      return 'table table-striped align-middle';
    }
  },
  methods: {
    ...mapMutations([
      'removeElementFromSideCommentList',
      'updateElementInSideCommentList'
    ]),
    deleteComment(comment) {
      this.removeElementFromSideCommentList(comment);
    },
    openCommentPanelForIndex(index) {
      this.isCommentPanelOpened = !this.isCommentPanelOpened;
      this.openedIndex = index;
      this.tempComment = this.getSideCommentList[index].content;
    },
    update() {
      this.updateElementInSideCommentList({
        id: this.openedIndex,
        content: this.tempComment
      })
    },
    closePickerIfOpen() {
      if(this.isCommentPanelOpened) {
        this.isCommentPanelOpened = false
        this.openedIndex = null;
      }
    },
    stripContentIfTooLong(content) {
      if(content !== null && content.length > 30) {
        return content.substring(0, 30) + '...'
      }
      return content
    },
  }
}
</script>

<style scoped>

.icon-btn {
  padding: 0 0.1rem 0 0.1rem;
}

.comment-area {
  resize: none;
  width: 90%;
  height: 15rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.btn-action {
  font-size: smaller;
}

.table {
  text-align: center;
}

thead {
  background: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: rgba( var(--accordion-bg-color), 0.8);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

</style>