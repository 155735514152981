<template>
  <div>
    <h1 class="content-title mb-3">{{ $t("tools") }}</h1>
    <div id="accordionTools" class="accordion">
      <BookmarkList />
      <HighlightList />
      <CommentList />
      <VisitedPageList />
    </div>
  </div>
</template>

<script>
import BookmarkList from "../components/Tools/BookmarkList";
import HighlightList from "../components/Tools/HighlightList";
import VisitedPageList from "../components/Tools/VisitedPageList";
import CommentList from "../components/Tools/CommentList";

export default {
  name: "ToolsView",
  components: {
    CommentList,
    BookmarkList,
    HighlightList,
    VisitedPageList,
  },
};
</script>

<style scoped></style>
