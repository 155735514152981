<template>
  <div class="dropdown">
    <button
      id="dropdownSettings"
      class="btn"
      type="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <font-awesome-icon
        icon="cog"
        style="color: rgba(var(--on-background-color), 0.87)"
      />
    </button>
    <ul
      class="dropdown-menu dropdown-menu-end"
      aria-labelledby="dropdownSettings"
    >
      <li>
        <h6
          class="dropdown-header"
          style="color: rgba(var(--on-background-color), 0.87)"
        >
          Settings
        </h6>
      </li>
      <li><ThemePicker class="dropdown-item item" /></li>
    </ul>
  </div>
</template>

<script>
import ThemePicker from "@/components/Settings/ThemePicker";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog } from "@fortawesome/free-solid-svg-icons";

library.add(faCog);

export default {
  name: "SettingsComponent",
  components: {
    ThemePicker,
  },
};
</script>

<style scoped>
.dropdown {
  margin-left: 0.5rem;
}
.dropdown-menu {
  background-color: rgba(var(--menu-bg-color), 1);
  width: 13rem;
}
.dropdown-item {
  color: rgba(var(--on-background-color), 0.87);
}
.dropdown-header {
  padding: 0.25rem 1rem 0.25rem 1rem;
}
.item {
  width: 95%;
  margin-left: 0.3rem;
}
.item:hover {
  background-color: var(--bg-color-08dp);
  color: rgba(var(--on-background-color), 0.87);
  border-radius: 0.25rem;
  size: 70rem;
}
</style>
