<template>
  <div class="bookmark-component">
    <span v-if="hasFullText">{{ $t("shortBookmark") }}</span>
    <button class="btn" @click="updateBookmark()">
      <font-awesome-icon
        v-if="!bookmarkListContainsCurrentPage()"
        :icon="['far', 'star']"
        style="color: rgba(var(--on-background-color), 0.6)"
        size="lg"
      />
      <font-awesome-icon
        v-else
        :icon="['fas', 'star']"
        style="color: yellow"
        size="lg"
      />
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";

library.add(faStarRegular, faStar);

export default {
  name: "BookmarkComponent",
  data() {
    return {
      hasFullText: false,
    };
  },
  computed: {
    ...mapGetters(["getBookmarkList"]),
    iconType() {
      if (this.bookmarkListContainsCurrentPage()) {
        return ["fas", "star"];
      }
      return ["far", "star"];
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations([
      "addElementToBookmarkList",
      "removeElementFromBookmarkList",
    ]),
    updateBookmark() {
      let bookmarks = this.getBookmarkList;
      if (bookmarks.includes(this.$route.name)) {
        this.removeElementFromBookmarkList(this.$route.name);
      } else {
        this.addElementToBookmarkList(this.$route.name);
      }
    },
    bookmarkListContainsCurrentPage() {
      return this.getBookmarkList.includes(this.$route.name);
    },
    handleResize() {
      this.hasFullText = window.innerWidth > 992;
    },
  },
};
</script>

<style scoped>
.btn {
  padding: 0 0.25rem 0.25rem 0.25rem;
}
</style>
