<template>
  <div class="comment-component">
    <span v-if="hasFullText">Commentaires</span>
    <button
      class="btn"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
      @click="toggleContentForSideComments()"
    >
      <font-awesome-icon
        v-if="
          $store.getters.getSideCommentContentFromPageName($route.name) === null
        "
        :icon="['far', 'comment']"
        style="color: rgba(var(--on-background-color), 0.6)"
        size="lg"
      />
      <font-awesome-icon
        v-else
        :icon="['fas', 'comment']"
        style="color: var(--primary-color)"
        size="lg"
      />
    </button>
  </div>
  <div
    id="offcanvasRight"
    class="offcanvas offcanvas-comment offcanvas-end"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Commentaires liés à la page</h5>
      <button
        type="button"
        :class="closeButtonClass"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="toggleContentForSideComments()"
      ></button>
    </div>
    <div class="offcanvas-body">
      <textarea
        id="side-comment"
        v-model="comment"
        class="side-comment-area"
        placeholder="Entrez vos commentaires liés à cette page"
      ></textarea>
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-success me-2"
          :disabled="comment === '' || comment === null"
          @click="saveComment()"
        >
          Sauvegarder
        </button>
        <button
          class="btn btn-danger"
          :disabled="comment === '' || comment === null"
          @click="deleteComment()"
        >
          Effacer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faComment as faCommentRegular } from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";

library.add(faComment, faCommentRegular);

export default {
  name: "SideCommentBar",
  emits: ["toggleSideComment"],
  data() {
    return {
      id: null,
      comment: "",
      isInitial: true,
      hasFullText: false,
    };
  },
  computed: {
    ...mapGetters(["getSideCommentList", "getSideMenuState", "getTheme"]),
    getCurrentComment() {
      return this.$store.getters.getSideCommentContentFromPageName(
        this.$route.name
      );
    },
    closeButtonClass() {
      if (this.getTheme === "dark") {
        return "btn-close text-reset btn-close-dark";
      }
      return "btn-close text-reset btn-close-light";
    },
  },
  watch: {
    $route(val) {
      const currentPageComment = this.$store.getters.getSideCommentFromPageName(
        val.name
      );
      this.comment =
        currentPageComment !== undefined ? currentPageComment.content : "";
      this.id =
        currentPageComment !== undefined
          ? currentPageComment.id
          : this.getSideCommentList.length;
      this.isInitial = this.comment === "";
    },
    getCurrentComment() {
      this.comment = this.$store.getters.getSideCommentContentFromPageName(
        this.$route.name
      );
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations([
      "addElementToSideCommentList",
      "updateElementInSideCommentList",
      "toggleSideCommentState",
      "toggleSideMenuState",
      "removeElementFromSideCommentList",
    ]),
    saveComment() {
      const payload = {
        id: this.id,
        content: this.comment,
        pageName: this.$route.name,
      };
      if (this.getCurrentComment === null || this.getCurrentComment === "") {
        this.addElementToSideCommentList(payload);
      } else {
        this.updateElementInSideCommentList(payload);
      }
    },
    deleteComment(comment) {
      this.removeElementFromSideCommentList(comment);
      this.comment = "";
    },
    toggleContentForSideComments() {
      this.toggleSideCommentState();
      if (this.getSideMenuState) {
        this.toggleSideMenuState();
      }
      this.$emit("toggleSideComment");
    },
    handleResize() {
      this.hasFullText = window.innerWidth > 992;
    },
  },
};
</script>

<style scoped>
.btn {
  padding: 0 0.25rem 0.25rem 0.25rem;
}

@media (max-width: 700px) {
  .offcanvas-end {
    top: 4.4rem;
    width: 700px;
  }
}
@media (min-width: 700px) {
  .offcanvas-end {
    top: 4.4rem;
    width: 400px;
  }
}

.side-comment-area {
  resize: none;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  height: 92%;
  box-sizing: border-box;
}

.btn-close-light {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 0.7;
}

.btn-close-dark {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 1;
}
</style>
