<template>
  <h1 class="content-title">Les enjeux éthiques de l'intelligence artificielle en santé</h1>
  <div class="mb-4 mt-4">
    <p>La santé est un domaine où les progrès en IA sont très prometteurs pour améliorer la qualité des soins et prévenir les maladies. Ces percées technologiques soulèvent toutefois des enjeux éthiques d’intérêt public. Voici les principaux enjeux et quelques pistes de solution à explorer pour les appréhender.</p>
    <h4>Protéger la propriété et la confidentialité des données</h4>
    <p>Afin d’obtenir des résultats précis, il est nécessaire d’entraîner les modèles d’apprentissage automatique avec beaucoup de données. Les modèles sont perfectionnés de manière continue à l’aide de nouvelles données sur les patients. Certaines personnes ne sont pas à l’aise avec l’idée que leurs données de santé soient partagées avec d’autres organisations. Elles pourraient, entre autres, craindre que des compagnies d’assurance les discriminent parce que leurs données suggèrent qu’elles sont plus susceptibles de contracter certaines maladies. L’obtention du consentement des patients est donc nécessaire avant toute utilisation de leurs informations personnelles. En expliquant les usages, les avantages et les risques de ce partage de données personnelles, on permettra aux patients de prendre leur décision de manière éclairée. Il est également recommandé de préciser comment les données seront anonymisées et protégées.</p>
    <p>Le fait de retirer le nom d’une personne d’une banque de données ne suffit pas toujours pour anonymiser ses données personnelles. En effet, en croisant d’autres données, il peut être possible de retracer son identité. Il est donc prudent de faire des vérifications au préalable afin de s’assurer que les données sont réellement anonymes.</p>
    <h4>Assurer la cybersécurité</h4>
    <p>Évidemment, le fait d’utiliser davantage l’IA exige un recours continu à l’informatique et augmente la vulnérabilité des organisations au cybercrime. Les multiples points d’accès au réseau les exposent aux attaques de pirates informatiques.</p>
    <p>Une offensive réussie pourrait avoir plusieurs répercussions. Financièrement, il se peut que les cyberpirates exigent des sommes d’argent en échange du contrôle qui a été ravi. Sur le plan de la sécurité physique, les robots victimes d’une cyberattaque pourraient causer des blessures s’ils étaient manipulés de la mauvaise façon. Enfin, du point de vue de la vie privée, il y a un risque que les données médicales personnelles des patients soient piratées et utilisées de manière malveillante ou négligente.</p>
    <p>Compte tenu de ces risques, la prudence impose de tester la robustesse du niveau de sécurité informatique sur l’ensemble du réseau. Des mises à jour et des vérifications fréquentes permettront de déceler les failles et de les corriger de manière à garantir un niveau de cybersécurité optimal.</p>
    <h4>Préserver la responsabilité, l’autonomie et l’imputabilité humaine</h4>
    <p>Bien que l’IA offre une impressionnante puissance de calcul, elle ne doit pas miner l’autonomie des professionnels de la santé. L’IA n’est pas à l’abri des risques d’erreurs. Lorsqu’une erreur est observée, le professionnel de la santé doit en comprendre l’origine pour pouvoir la corriger. L’IA est d’abord un outil d’aide à la prise de décisions, des décisions qui sont parfois complexes. Ces technologies ne peuvent pas remplacer l’avis d’un professionnel et leur emploi doit être encadré.</p>
    <p>Les recommandations et les prédictions de l’IA sont parfois présentées sans explications ou justifications. Le manque de transparence quant aux données, aux processus et au fonctionnement des algorithmes peut s’avérer problématique lorsque l’expert veut s’assurer qu’une suggestion émise est adaptée au cas particulier d’un patient. En présence d’une condition médicale rare, mais déterminante, qui n’est pas prise en compte par l’IA, le spécialiste doit comprendre le processus, le résultat et la recommandation pour être en mesure d’en évaluer la pertinence. Le médecin doit aussi pouvoir expliquer les résultats et les recommandations au patient.</p>
    <p>L’IA ne peut se développer au détriment de la confiance accordée à l’expertise et au jugement professionnels du personnel médical. Le but de l’IA est d’apporter un soutien aux professionnels de la santé. L’intervention humaine est essentielle pour confronter les analyses des systèmes d’IA et valider les conclusions tout en préservant le sentiment d’autonomie du personnel. Pour faciliter l’aide à la prise de décisions, il y a lieu de privilégier le recours à des outils qui définissent de manière transparente les motifs et les justifications des recommandations.</p>
    <p>Le manque de transparence quant au raisonnement qui a conduit au choix d’un traitement peut être à l’origine de tensions chez les professionnels, qui se trouvent à devoir choisir de suivre leur intuition professionnelle ou la recommandation émise par l’IA. L’utilisation de l’IA doit se développer dans le respect des compétences et de l’autonomie professionnelle du personnel de la santé.</p>
    <p>Ces situations délicates incitent à réfléchir à l’imputabilité et à la responsabilité des professionnels de la santé qui utilisent l’IA. Quelle part de responsabilité incombe au personnel médical dans le choix de suivre ou non les recommandations de l’IA&nbsp;? Qui est imputable&nbsp;? Le fabricant, le développeur, le programmeur, le clinicien&nbsp;? Quels sont leurs rôles respectifs&nbsp;? Une réflexion collective impliquant les patients s’impose.</p>
    <p>Enfin, il faut déterminer en amont quels types et marges d’erreurs sont acceptables selon la nature (humaine ou informatique) des situations problématiques, car cela peut en faciliter la gestion. L’adoption d’une procédure pour composer avec ces situations est une mesure efficace.</p>
    <h4>Éviter la présence de biais et de discrimination</h4>
    <p>Pour bien performer, les systèmes d’IA sont entraînés avec des données à partir desquelles ils dégagent des tendances. Étant donné que toutes les données fournies sont produites ou traitées par des humains, il se peut qu’elles soient biaisées ou fausses. En effet, les humains ne sont pas à l’abri de présenter certains biais ou de commettre des erreurs. Si on leur fournit des données incorrectes, les systèmes d’IA apprendront à reproduire ces biais ou ces erreurs.</p>
    <p>Par exemple, des outils de reconnaissance d’images sont utilisés pour détecter plus facilement certaines affections de la peau. Si les photos de personnes caucasiennes sont surreprésentées dans les données servant à entraîner un système, celui-ci est moins efficace pour détecter les mêmes maladies chez les personnes d’autres ethnicités. Cela risque de mener à des recommandations de diagnostics erronés qui pourraient aller jusqu’à affecter la santé d’un patient. Dans un autre contexte, un modèle peut manquer de données sur l’efficacité d’un traitement pour un groupe d’âge particulier. Il est alors nécessaire de l’enrichir de données sur des patients qui appartiennent à ce groupe d’âge. Plusieurs autres facteurs comme le statut socioéconomique et le sexe doivent être considérés pour s’assurer qu’aucun groupe de données n’est sous-représenté.</p>
    <p>Par ailleurs, certaines communautés éloignées et parfois marginalisées ont un accès restreint à certaines technologies, comme Internet. Les données sur ces patients pourront-elles alimenter les modèles d’apprentissage automatique&nbsp;? Lors de l’implantation des modèles d’apprentissage automatique et des technologies de l’IA, la prise en compte de la variété des conditions de vie des patients assure qu’aucun groupe n’est sous-représenté. Cette sensibilité permettra d’éviter de créer des situations discriminatoires qui pourraient poser un risque pour la santé de certains patients.</p>
    <p>Il serait prudent de définir les circonstances qui pourraient empêcher certaines personnes de recevoir les soins adéquats auxquels elles ont droit. Une évaluation rigoureuse de l’usage potentiel d’une technologie de l’IA facilitera la sélection d’outils favorisant un traitement équitable des patients. À nouveau, il convient de préconiser les dispositifs plus transparents quant aux données, au raisonnement et aux motifs des recommandations émises. L’offre de formations pour sensibiliser le personnel aux biais et aux formes de discriminations que l’IA peut causer peut également avoir des effets positifs.</p>
    <h4>Assurer la qualité des données</h4>
    <p>À l’heure actuelle, la plupart des données utilisées dans le milieu de la santé n’ont pas été récoltées dans le but d’entraîner des systèmes d’IA. Ces données manquent d’uniformité. Par exemple, il se peut que certaines données ne précisent pas le contexte du traitement, qu’elles soient notées à l’aide de différentes abréviations ou qu’elles comportent des erreurs de frappe. Il est aussi possible qu’elles ne répondent pas nécessairement aux exigences de qualité, par exemple en ne précisant pas les antécédents familiaux du patient atteint d’une maladie. Enfin, certaines méthodes de collecte des données ne préservent pas l’anonymat des patients.</p>
    <p>Avant d’employer des données, il est donc nécessaire de vérifier qu’elles répondent aux exigences de qualité, d’uniformité et de confidentialité. Quant aux nouvelles données destinées à enrichir les modèles d’apprentissage automatique, elles doivent être exemptes de biais. Plusieurs organisations, comme le Consortium en santé numérique de Montréal, se penchent sur les normes et les pratiques qui favorisent le développement de bases de données fiables.</p>
    <h4>Respecter le bien-être des patients</h4>
    <p>Puisqu’ils seront forcément touchés par l’intégration de l’IA dans le domaine de la santé, il est essentiel d’assurer la préservation du bien-être des patients qui côtoieront ces nouvelles technologies. Par exemple, l’implication de robots accompagnateurs ou d’agents conversationnels dans la prise en charge de patients soulève quelques interrogations. Certaines personnes pourraient avoir de la difficulté à créer un lien de confiance avec une machine et regretter le contact humain du personnel médical. De plus, les différents dispositifs, comme les robots, les capteurs et les autres appareils nécessaires à la mise en place de l’IA, peuvent augmenter le risque de blessure, d’inconfort ou de stress chez les patients.</p>
    <p>Afin de bien gérer ce genre de situation, l’accès à une évaluation des impacts potentiels de l’IA sur le bien-être des patients permet d’adopter les mesures qui s’imposent. Il est aussi utile de donner aux patients la possibilité de s’entretenir avec un membre du personnel s’ils ne sont pas à l’aise en compagnie d’un robot. De nouveaux aménagements de l’espace peuvent également contribuer à diminuer les risques d’accident.</p>
  </div>
</template>
<script>
export default {
  name: "SanteEthics"
}
</script>

<style scoped>

</style>