<template>
  <h1 class="content-title"> {{ $t('industrieManufacturiereHome') }} </h1>
  <div class="mb-4 mt-4">
    <p>Les solutions d’intelligence artificielle révolutionnent l’industrie manufacturière pour créer l’usine intelligente de demain. De la conception d’un produit jusqu’à sa distribution, l’IA peut devenir un maillon fort de votre chaîne de production.</p>
    <p>Vous êtes invité à utiliser le livre numérique pour noter vos idées ou vos réflexions, ou encore pour surligner un extrait de texte particulièrement pertinent à vos yeux.</p>
  </div>
</template>

<script>
export default {
  name: "IndustrieManufacturiereHome"
}
</script>

<style scoped>

</style>