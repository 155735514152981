<template>
  <h1 class="content-title"> {{ $t('agricultureHome') }} </h1>
  <div class="mb-4 mt-4">
    <p>Pour répondre à la croissance démographique, aux enjeux climatiques et environnementaux ainsi qu’à la fluctuation des prix, de plus en plus d’agriculteurs s’intéressent aux solutions proposées par l’intelligence artificielle. Au champ comme à l’étable, l’IA peut devenir le génie en herbe des agriculteurs&nbsp;!</p>
    <p>Vous êtes invité à utiliser le livre numérique pour noter vos idées ou vos réflexions, ou encore pour surligner un extrait de texte particulièrement pertinent à vos yeux.</p>
  </div>
</template>

<script>
export default {
  name: "AgricultureHome"
}
</script>

<style scoped>

</style>