<template>
  <h1 class="content-title">Les cas d’usage de l’intelligence artificielle en agriculture</h1>
  <div class="mb-4 mt-4">
    <p>Les cultivateurs entrent dans l’ère de « l’agriculture de précision » grâce à l’intelligence artificielle. Cette approche permet d’adopter des pratiques qui répondent aux besoins particuliers des cultures pour optimiser les rendements et les investissements. Les cas d’usage de l’IA sont nombreux en agriculture et ils sont en constante évolution. Voici quelques exemples d’utilisations qui témoignent du potentiel de ces nouvelles technologies.</p>
    <h3>La valorisation des sols</h3>
    <p>De plus en plus d’agriculteurs adoptent l’IA et des applications numériques pour valoriser leurs sols. Voici quelques exemples d’utilisations.</p>
    <h4>La robotisation du désherbage</h4>
    <p>Grâce à la reconnaissance d’images, des robots peuvent effectuer le désherbage des cultures maraîchères. Les robots peuvent travailler avec une grande précision à deux centimètres près des plants. Ils sont autonomes&nbsp;: ils se déplacent tout seuls dans les champs et peuvent se dégager quand ils s’embourbent. Ils ont une durée active de neuf heures, sans égard aux conditions météorologiques, et retournent d’eux-mêmes à la station de recharge. Ils peuvent aussi manipuler différents types d’instruments agricoles.</p>
    <h4>Un tracteur autonome pour le labourage</h4>
    <p>Il est aussi possible d’utiliser un tracteur autonome pour effectuer les tâches de labourage. Pendant que le tracteur évolue seul, l’agriculteur peut vaquer à d’autres occupations, tout en gardant un œil sur ce qui se passe grâce à une application sur son téléphone cellulaire. Le tracteur peut aussi tirer avantage des conditions météorologiques favorables et travailler pendant des heures sans arrêt, contrairement à l’agriculteur qui doit s’arrêter pour dormir à un certain moment.</p>
    <p>L’agriculteur programme la surface du champ à labourer dans l’ordinateur du tracteur. Ensuite, le tracteur se déplace par lui-même dans la zone autorisée. Équipé de caméras qui lui assurent une vision à 360 degrés, le tracteur détecte les obstacles et évalue les distances. Les images captées par les caméras sont traitées en temps réel par un réseau de neurones qui effectue des calculs au centième de milliseconde. Le tracteur peut même s’arrêter si un obstacle se présente soudainement devant lui, comme une large pierre, un animal ou une personne&nbsp;; le cas échéant, il s’immobilise immédiatement et envoie une alerte sur le cellulaire de l’agriculteur.</p>
    <h4>Le contrôle de la qualité des sols</h4>
    <p>La santé des sols est un enjeu majeur en agriculture. Par le biais de l’apprentissage automatique combiné à de l’imagerie satellite et à des capteurs et sondes sur le terrain, les agriculteurs peuvent analyser les sols de façon précise pour connaître leur composition chimique (pH, taux d’azote, minéraux présents, etc). Il est aussi possible de détecter rapidement et en temps réel la présence de microbes, de mutations génétiques, de contaminants et de plantes invasives. En cas d’anomalie, le système émet des alertes et fournit des recommandations à l’agriculteur en vue de régler le problème. L’agriculteur peut ainsi assurer une gestion plus rigoureuse de l’eau et du dosage des engrais et des pesticides. Il est aussi en mesure d’adapter le calendrier de semis.</p>
    <h4>Les technologies de pulvérisation</h4>
    <p>Grâce à l’analyse de données, les agriculteurs peuvent être informés de la présence de parasites et de mauvaises herbes dans leurs champs. Une technologie de pulvérisation robotisée, par exemple par drone, peut alors être activée. Cela permet de cibler la pulvérisation de manière précise, ce qui réduit grandement l’utilisation de pesticides.</p>
    <p>Ces techniques permettent aussi de répandre les semis à l’aide d’un drone. Dans un champ de maïs, cela permet de devancer le calendrier des semis à l’automne sans avoir à recourir à la machinerie agricole.</p>
    <h3>L’optimisation de la récolte</h3>
    <p>De plus en plus d’entreprises du secteur de l’agriculture adoptent l’IA et des applications numériques pour mener les activités en lien avec la récolte. Voici des exemples d’utilisations existantes.</p>
    <h4>L’ajustement des moissonneuses-batteuses</h4>
    <p>Il existe des moissonneuses-batteuses équipées de capteurs et de caméras qui permettent d’analyser différents éléments (qualité du grain, présence d’impuretés, pertes au battage, etc.) tout en tenant compte des conditions climatiques. Grâce à l’analyse des données, la machine peut s’ajuster en modifiant les paramètres de récolte de manière automatique et précise. La machine recueille également des données utiles pour prendre des décisions éclairées lors des récoltes ultérieures et donc améliorer les rendements des années à venir.</p>
    <h4>La robotisation de la cueillette</h4>
    <p>Les robots intelligents et l’Internet des objets (IdO) sont des pistes de solution intéressantes pour les agriculteurs qui doivent faire face à une pénurie de main-d’œuvre. Des machines peuvent récolter différents types de fruits et de légumes grâce à des bras robotisés et à des caméras qui leur permettent de reconnaître les produits à cueillir parmi le feuillage. Les machines peuvent également faire d’autres tâches, comme l’inventaire des récoltes et le désherbage. Ces systèmes intelligents peuvent fonctionner jour et nuit, peu importe les conditions météorologiques.</p>
    <h4>Le tri des légumes</h4>
    <p>La reconnaissance d’images et l’apprentissage automatique peuvent accélérer des opérations, comme le tri des pommes de terre dans une machine de triage. Le système intelligent travaille dès l’introduction de la matière dans la trémie. Ainsi, les pommes de terre sont séparées des roches, des mottes de terre, de la végétation ou d’autres indésirables. Elles sont ensuite triées selon des critères de qualité préalablement établis par l’agriculteur. La machine peut reconnaître diverses imperfections sur les pommes de terre, telles que des taches, des parasites et des virus, et rejeter les légumes qui ne respectent pas les normes.</p>
    <h4>Les chambres de culture</h4>
    <p>Les chambres de culture servent à faire de l’agriculture hiver comme été, peu importe le climat. Des capteurs et des senseurs peuvent être installés pour recueillir des données. Tirant profit de l’analyse de ces données, les systèmes peuvent recréer des éléments comme le vent, l’arrosage et l’éclairage afin d’optimiser la culture. Ils sont également en mesure de détecter différents stress de culture, comme la présence d’envahisseurs ou la surfertilisation, et de générer des alertes. Plus les systèmes apprennent, plus ils disposent d’informations pour prévoir les problèmes et intervenir en conséquence.</p>
    <h3>La prévention et le diagnostic de maladies</h3>
    <p>L’IA et les applications numériques peuvent être utilisées pour le diagnostic et la prévention des maladies dans le secteur de l’agriculture. Voici des exemples d’utilisations existantes.</p>
    <h4>La prévention des maladies</h4>
    <p>Le domaine de l’élevage de poulets est un bel exemple de l’utilisation de l’IA pour prévenir les maladies. Ainsi, l’IA permet d’identifier les poulets atteints de grippe aviaire et d’adopter rapidement des mesures de prévention pour contrôler la maladie. Des caméras et des capteurs installés dans les poulaillers détectent des changements dans l’état des poulets, dans leur comportement ainsi que dans leur environnement. L’algorithme peut alors reconnaître les symptômes de la maladie et informer l’éleveur de l’emplacement des poulets infectés.</p>
    <h4>La surveillance de la santé des vaches laitières</h4>
    <p>La santé des vaches peut être surveillée en temps réel grâce à des lactoducs équipés de capteurs optiques qui étudient la composition du lait sans l’altérer. Cette technologie analyse les molécules et est en mesure, par le biais de l’apprentissage automatique, de détecter les variations dans la composition du lait. Lorsque des anomalies sont relevées, le système avise l’éleveur. Ce dernier peut ensuite prendre en charge le suivi de l’animal.</p>
    <h4>L’utilisation de drones pour le diagnostic</h4>
    <p>Des drones, combinés à l’apprentissage automatique, peuvent être utiles pour établir des diagnostics dans les champs. Les appareils captent des images à haute résolution en volant près des plants. Ces images sont ensuite analysées et les algorithmes peuvent prédire l’apparition de problèmes, comme des moisissures ou des maladies.</p>
    <h4>L’obtention de données météorologiques</h4>
    <p>Des stations météorologiques intelligentes permettent de suivre les conditions en temps réel et à distance&nbsp;: précipitations, température, humidité, vitesse, direction du vent, etc. Les systèmes tiennent compte des données historiques sur différents événements, comme les gelées au sol, les inondations et les canicules, pour prédire la probabilité que ces événements se reproduisent. En cas de besoin, ils émettent des alertes et l’agriculteur peut organiser ses activités en conséquence.</p>
    <h4>La localisation et l’identification d’infestations</h4>
    <p>Il est possible d’utiliser la reconnaissance d’images et l’apprentissage automatique pour surveiller les plants. Des caméras peuvent capter des images d’insectes présents dans les champs. Le système peut ensuite classer les images et reconnaître celles qui montrent des espèces nuisibles. L’agriculteur reçoit alors une alerte qui l’avise de l’anomalie.</p>
    <h3>La réduction des risques associés à la gestion d’une exploitation agricole</h3>
    <p>De plus en plus d’entreprises adoptent l’IA et des applications numériques pour réduire les risques associés à la gestion d’une exploitation agricole. Voici des exemples d’utilisations existantes.</p>
    <h4>La gestion des récoltes</h4>
    <p>La gestion des récoltes peut être optimisée grâce à l’IA. Diverses tâches, dont la surveillance, la détection, l’observation et la collecte de données dans les champs, peuvent être confiées à des drones. Les données tiennent compte de nombreux facteurs, comme la topographie, la hauteur des plants, l’état des semences, la fertilisation et l’hydrométrie. Ces données peuvent optimiser la prise de décision de l’agriculteur et, par conséquent, faciliter son travail.</p>
    <h4>La culture hydroponique</h4>
    <p>L’IA aide les agriculteurs à prendre de meilleures décisions pour la culture hydroponique. L’apprentissage automatique recueille et traite des données qui aident les agriculteurs à faire une meilleure planification et à améliorer la rentabilité de leur exploitation. Par exemple, en contrôlant l’intensité de la lumière dans leurs installations, l’IA permet de réduire les frais en électricité. Ou encore, l’utilisation de systèmes intelligents pour calculer de manière très précise la quantité de nutriments requis pour certains types de cultures peut générer des économies.</p>
    <h4>L’évaluation de l’état des exploitations</h4>
    <p>L’analyse de données et l’Internet des objets peuvent contribuer à l’évaluation de l’état des exploitations. Par le biais de technologies intelligentes comme des capteurs plantés en terre, des drones qui cartographient les sols et des caméras, il devient possible de connaître en détail l’état des exploitations. Les analyses réalisées permettent de prédire l’apparition de problèmes, mais aussi de fournir des recommandations tenant compte des variations de climat, de la présence de maladies, ou encore de la situation du marché. Les technologies peuvent donc aider l’agriculteur à prendre des décisions en temps réel.</p>
    <h4>La gestion des poulaillers</h4>
    <p>Les éleveurs peuvent utiliser l’analyse des données et l’Internet des objets pour gérer les poulaillers. Certaines activités, dont la distribution de l’alimentation, le contrôle de l’éclairage et le ramassage des œufs, peuvent être réalisées de manière automatique. Différentes technologies, comme des microphones, peuvent être combinées à l’apprentissage automatique pour veiller au bien-être des poulets. Les microphones enregistrent les sons ambiants au quotidien dans le poulailler. Lorsque le système détecte des bruits qui dévient de la normale, l’éleveur reçoit une alerte et peut vérifier ce qui se passe (température trop élevée, trop basse, etc.).</p>
    <h4>La santé des végétaux</h4>
    <p>L’apprentissage automatique et les modèles prédictifs permettent d’améliorer la santé des végétaux. Il est en effet possible de placer des électrodes sur des plantes afin de surveiller leurs signaux électrophysiologiques. Le système enregistre des données sur la croissance des plantes, leur développement et leurs mécanismes de défense. Il génère des modèles qui permettent de distinguer les signaux normaux de ceux qui indiquent un stress. Le système peut identifier en temps réel différents problèmes, comme des maladies, la présence d’insectes nuisibles, des carences en nutriments ou encore un manque d’eau.</p>
    <h3>L’alimentation des animaux de ferme</h3>
    <p>L’IA offre la possibilité d’optimiser les activités liées à l’alimentation des animaux. Voici des exemples d’utilisations existantes.</p>
    <h4>Le contrôle de l’alimentation des porcs et des vaches</h4>
    <p>La robotisation sert à contrôler la quantité et la qualité des aliments distribués aux porcs ou aux vaches. Chaque animal porte à l’oreille une étiquette que la station d’alimentation électronique lit pour identifier l’animal et lui livrer une quantité de nourriture en fonction de ses besoins nutritionnels. L’éleveur contrôle ainsi l’alimentation des animaux. Il peut adapter le régime de chaque animal, par exemple en lui fournissant la bonne quantité de nutriment au bon moment.</p>
    <h4>Le contrôle de la consommation d’eau des truies</h4>
    <p>La compagnie Asserva a créé un système qui ajuste de façon personnalisée la consommation d’eau des truies en maternité. Les aliments et l’eau des truies sont déposés dans l’auge. Des sondes analysent le niveau d’eau et de nourriture. Quand l’auge est vide, le système dépose de nouveau de l’eau et de la nourriture jusqu’à ce que la ration journalière soit atteinte. Un logiciel accessible via une tablette ou un téléphone cellulaire permet de suivre la consommation des truies. Le système pourrait augmenter le poids des porcelets de près de 300 grammes.</p>
    <h4>La surveillance du poids des poulets</h4>
    <p>L’analyse des données peut être un allié précieux pour suivre l’évolution du poids des poulets. Des algorithmes peuvent en effet estimer de manière très précise le poids des poulets selon la quantité de nourriture consommée. Les modèles prédictifs tiennent compte des variations dans les courbes de croissance et peuvent ajuster le système de distribution de nourriture en conséquence.</p>
    <h3>La traite des vaches</h3>
    <p>L’IA permet de robotiser la traite des vaches et ainsi de pallier la pénurie de main-d’œuvre.</p>
    <h4>La traite des vaches</h4>
    <p>Il est possible d’utiliser un robot pour la traite des vaches. Cette technologie permet par exemple de traire jusqu’à 65 vaches par jour, ou 2300 litres de lait. De plus, la robotisation de la traite des vaches génère des informations et des rapports de contrôle. Ces rapports renseignent l’agriculteur sur la production du lait du troupeau, la composition du lait, le niveau de protéines dans le lait et la prévalence de maladies.</p>
    <h3>L’entreposage des récoltes</h3>
    <p>L’IA et les applications numériques peuvent améliorer l’entreposage des récoltes. La salubrité et la traçabilité des aliments à la ferme sont une façon pour les agriculteurs de démontrer aux consommateurs qu’ils ont un souci de la qualité et de la sécurité sanitaire des aliments. L’IA peut grandement faciliter le travail des agriculteurs dans ce domaine. Voici des exemples d’utilisations.</p>
    <h4>Les conditions d’entreposage</h4>
    <p>L’IA peut aider à réguler de façon autonome les conditions d’entreposage du grain dans les silos et celles des légumes dans les entrepôts. Cela contribue à maximiser les conditions d’entreposage et à réduire les pertes. Équipé de capteurs qui enregistrent en continu des données dans l’environnement d’entreposage (température, humidité, etc.), le système peut faire les ajustements nécessaires pour optimiser les conditions d’entreposage en modifiant certains paramètres, notamment la ventilation.</p>
    <h4>La traçabilité et l’entreposage</h4>
    <p>Il est aussi possible, au moment de la récolte, d’utiliser des technologies simples qui vont, par exemple, calculer le rendement. Ces outils technologiques sont ajoutés à la récolteuse, au convoyeur qui emplit le camion et au convoyeur empileur dans l’entrepôt. Pendant que le convoyeur emplit le camion, des capteurs saisissent le poids des légumes, évaluent les impuretés du sol (pierres, sable, poussière) et enregistrent d’autres données (température, humidité, etc.). Le système identifie le camion en lui attribuant un numéro de lot, une donnée de géolocalisation (une cartographie de la superficie du champ récolté dans ce lot), la date et l’heure de la récolte. Cette identification par lots rend possible un classement par lots dans l’entrepôt&nbsp;; ainsi, des espaces dans l’entrepôt correspondent à des emplacements dans les champs. Cela permet de retracer facilement une variété de légume ou d’isoler un lot malade, par exemple.</p>
    <h3>L’agriculture durable et la préservation de l’environnement</h3>
    <p>L’industrie agricole se soucie de plus en plus du développement durable, de la préservation des ressources naturelles, comme l’eau, et des impacts de ce secteur d’activité sur l’environnement. Les technologies en IA peuvent représenter une solution intéressante sur les plans agroalimentaire, économique et climatique. Voici quelques exemples de l’utilité de l’IA pour répondre à ces enjeux.</p>
    <h4>La préservation de l’eau</h4>
    <p>Il est possible d’utiliser un système économe en eau comme la micro-irrigation, couplé à des capteurs qui mesurent l’humidité du sol. Gérée par l’IA, cette installation apporte la quantité d’eau nécessaire à la croissance au pied de chaque plant.</p>
    <h4>L’agriculture hivernale</h4>
    <p>La culture maraîchère en serre pratiquée l’hiver est une solution de rechange intéressante pour réduire les impacts environnementaux liés à l’alimentation, en réduisant notamment la dépendance aux importations qui émettent des gaz à effet de serre lors du transport des marchandises réfrigérées.</p>
    <p>Ampoules DEL qui reproduisent l’énergie solaire et qui simulent le lever et le coucher du soleil, petit vent artificiel créé par des ventilateurs, arrosage, tout est contrôlé à la Ferme d’hiver, qui se spécialise dans la culture verticale des fraises. Les étapes de la culture seront bientôt automatisées à l’aide d’algorithmes. Même la chaleur dégagée par les ampoules refroidies à l’eau est récupérée pour chauffer la serre d’une ferme avoisinante qui fait la culture horizontale de 15&nbsp;000&nbsp;laitues.</p>
    <h4>La fertilisation de précision</h4>
    <p>La surfertilisation contribue à la dégradation des sols ainsi qu’à la pollution de l’eau et de l’air. Grâce à l’IA, il serait possible de prendre une meilleure décision de fertilisation. De nombreux producteurs québécois ont amassé au fil des ans une quantité appréciable de données diverses telles que : données d’observations sur le terrain, fréquence et quantité de fertilisants utilisés, données météorologiques et captures d’images. Les algorithmes et le traitement d’images permettent de déterminer les doses optimales de fertilisant à utiliser et à quel moment les appliquer.</p>
  </div>
</template>

<script>
export default {
  name: "AgricultureCases"
}
</script>

<style scoped>

</style>